import { Component, OnInit } from '@angular/core';
import { UserProgramDetails } from 'src/app/core/model/user/user-program-details';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  showFlash: boolean = false;

  constructor(private userService: UserService) {}
  ngOnInit() {
    this.userService.getFlashDetails().then((x: UserProgramDetails) => {
      if (x) {
        this.showFlash = x.flashProgramAvailable;
      }
    });
  }
}
