import { Component, Input, OnInit } from '@angular/core';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { Content } from 'src/app/models/content.model';
import { ContentService } from 'src/app/content/content.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from 'src/app/layout/layout.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SupportService } from 'src/app/support/support.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { Settings } from 'src/app/entities/settings';

@Component({
  selector: 'app-presales-form',
  templateUrl: './presales-forms.component.html',
  styleUrls: ['./presales-forms.component.scss'],
})
export class PresalesFormsComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  bodyContent: string;
  sideContent: string;
  bottomLeftBoxContent: string;
  bottomMiddleBoxContent: string;
  bottomRightBoxContent: string;
  disclaimer: string;
  settings: Settings;
  constructor(
    private layoutService: LayoutService,
    private contentService: ContentService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private supportService: SupportService,
    private dialogService: DialogService,
    public settingsProvider: SettingsProvider,
  ) {
    this.settings = settingsProvider.settings.data;
  }

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyContent = this.baseContent.fields.find((f) => f.name === 'Body');
    const sideContent = this.baseContent.fields.find((f) => f.name === 'Side Content');
    const bottomLeftBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Left Box Content',
    );
    const bottomMiddleBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Middle Box Content',
    );
    const bottomRightBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Right Box Content',
    );
    const disclaimer = this.baseContent.fields.find((f) => f.name === 'Disclaimer ');

    this.bodyContent = bodyContent.value.value;
    this.sideContent = sideContent.value.value;
    this.bottomLeftBoxContent = bottomLeftBoxContent.value.value;
    this.bottomMiddleBoxContent = bottomMiddleBoxContent.value.value;
    this.bottomRightBoxContent = bottomRightBoxContent.value.value;
    this.disclaimer = disclaimer ? disclaimer.value.value : '';

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });

    const competitiveDisplacementContentField = this.baseContent.fields.find(
      (f) => f.name === 'Competitive Displacement Content',
    );
  }
}
