import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreSalesStoreService } from 'src/app/core/store/presales-store.service';
import { toInteger } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationDetailsInput } from 'src/app/models/confirmation-details-input';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SupportStoreService } from 'src/app/core/store/support-store.service';

@Component({
  selector: 'ps-presales-confirmation',
  templateUrl: './presales-confirmation.component.html',
  styleUrls: ['./presales-confirmation.component.scss'],
})
export class PresalesConfirmationComponent implements OnInit {
  showRejectionForm: boolean = false;
  showApprovalForm: boolean = false;

  hideNewPowerClientOption: boolean = false;

  rejectionForm: FormGroup = this.formBuilder.group({
    reason: [''],
    otherReason: [''],
  });

  approvalForm: FormGroup = this.formBuilder.group({
    reason: [''],
  });

  meetingId: number;
  assessmentId: number;
  competitiveDisplacementItemId: number;
  redHatItemId: number;
  action: string;
  identifier: string;
  clientIdentifier: string;
  reason: string;

  constructor(
    private preSalesStore: PreSalesStoreService,
    private supportStore: SupportStoreService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.meetingId = toInteger(this.route.snapshot.queryParamMap.get('meetingId'));
    this.assessmentId = toInteger(this.route.snapshot.queryParamMap.get('assessmentId'));
    this.competitiveDisplacementItemId = toInteger(this.route.snapshot.queryParamMap.get('itemId'));
    this.redHatItemId = toInteger(this.route.snapshot.queryParamMap.get('redHatItemId'));
    this.action = this.route.snapshot.queryParamMap.get('action');
    this.identifier = this.route.snapshot.queryParamMap.get('identifier');
    this.clientIdentifier = this.route.snapshot.queryParamMap.get('client');
    this.reason = this.route.snapshot.queryParamMap.get('reason');

    console.log('Meeting ID', this.meetingId);

    if (this.action === 'confirm') {
      if (this.meetingId && this.meetingId > 0) {
        this.preSalesStore.confirmMeetingDetails({
          meetingId: this.meetingId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
        } as ConfirmationDetailsInput);
      } else if (this.assessmentId && this.assessmentId > 0) {
        this.preSalesStore.confirmAssessmentDetails({
          assessmentId: this.assessmentId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
        } as ConfirmationDetailsInput);
      } else if (this.competitiveDisplacementItemId && this.competitiveDisplacementItemId > 0) {
        this.supportStore.confirmCompetitiveDisplacementItem({
          itemId: this.competitiveDisplacementItemId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
        } as ConfirmationDetailsInput);
      } else if (this.redHatItemId && this.redHatItemId > 0) {
        this.showApprovalForm = true;
      }
    } else if (this.action === 'reject') {
      this.showRejectionForm = true;

      if(this.redHatItemId && this.redHatItemId > 0) {
        this.hideNewPowerClientOption = true;
      }
    }
  }

  onSubmit() {
    if (this.rejectionForm.valid) {
      const reason = this.rejectionForm.value.reason;
      const otherReason = this.rejectionForm.value.otherReason;

      if (this.meetingId && this.meetingId > 0 && this.action) {
        this.preSalesStore.confirmMeetingDetails({
          meetingId: this.meetingId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
          reason: reason === '' ? otherReason : reason,
        } as ConfirmationDetailsInput);
      } else if (this.assessmentId && this.assessmentId > 0 && this.action) {
        this.preSalesStore.confirmAssessmentDetails({
          assessmentId: this.assessmentId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
          reason: reason === '' ? otherReason : reason,
        } as ConfirmationDetailsInput);
      } else if (this.competitiveDisplacementItemId && this.competitiveDisplacementItemId > 0) {
        this.supportStore.confirmCompetitiveDisplacementItem({
          itemId: this.competitiveDisplacementItemId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
          reason: reason === '' ? otherReason : reason,
        } as ConfirmationDetailsInput);
      } else if (this.redHatItemId && this.redHatItemId > 0) {
        this.supportStore.confirmRedHatItem({
          itemId: this.redHatItemId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
          reason: reason === '' ? otherReason : reason,
        } as ConfirmationDetailsInput);
      }
    }
  }

  onApprovalSubmit() {
    if (this.approvalForm.valid) {
      const reason = this.approvalForm.value.reason;
      if (this.redHatItemId && this.redHatItemId > 0) {
        this.supportStore.confirmRedHatItem({
          itemId: this.redHatItemId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
          reason: reason === '' ? this.reason : reason,
        } as ConfirmationDetailsInput);
      }
    }
  }
}
