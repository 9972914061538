import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';

import { PayTmContentTemplateComponent } from './components/pay-tm-content-template/pay-tm-content-template.component';
import { PayTmValidationContentTemplateComponent } from './components/pay-tm-validation-content-template/pay-tm-validation-content-template.component';
import { PaytmRoutingModule } from './pay-tm-routing.module';

@NgModule({
  declarations: [PayTmContentTemplateComponent, PayTmValidationContentTemplateComponent],
  imports: [CommonModule, SharedModule, TranslateModule, ReactiveFormsModule, PaytmRoutingModule],
  exports: [PayTmValidationContentTemplateComponent],
})
export class PayTmModule {}
