import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { AccountService } from 'src/app/account/account.service';
import { PointsSummary } from 'src/app/account/points-summary-models';
import { filter, skip, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Settings } from 'src/app/entities/settings';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { SalesClaimStoreService } from 'src/app/core/store/sales-claim-store.service';
import {
  DialogNgbService,
  NotificationStoreService,
  Notification,
} from '@motivforce/mx-library-angular';
import { TranslateService } from '@ngx-translate/core';
import { UserStoreService } from 'src/app/core/store/user-store.service';
import { UserProgramDetails } from 'src/app/core/model/user/user-program-details';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit, OnDestroy {
  public user: User;
  public impersonate = false;
  pointsSummary: PointsSummary;
  hasSalesToClaim: boolean = false;
  countSalesToClaim: number = 0;
  userIsSales: boolean = false;
  companyName: string;
  systemsDetails: UserProgramDetails;
  userIsFlash: boolean = false;

  notifications$ = this.notificationStore.notifications$;

  showKyi = false;
  showFlash = false;

  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private userStore: UserStoreService,
    private settingsStore: SettingsStoreService,
    private salesClaimStore: SalesClaimStoreService,
    private notificationStore: NotificationStoreService,
    private dialog: DialogNgbService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.userStore.flashDetails$
      .pipe(takeUntil(this.onDestroy$), filter(Boolean))
      .subscribe((flashDetails: UserProgramDetails) => {
        this.showFlash = flashDetails.flashProgramAvailable;
      });

    this.userStore.systemsDetails$
      .pipe(filter(Boolean), take(1))
      .subscribe((systemsDetails: UserProgramDetails) => {
        this.systemsDetails = systemsDetails;

        this.showKyi = systemsDetails.systemsProgramAvailable;
      });

    this.userStore.getFlashDetails();
    this.userStore.getSystemsDetails();

    if (this.authenticationService.getImpersonatedUserId()) {
      console.log(this.authenticationService.getImpersonatedUserId());
      this.impersonate = true;
    }

    this.userStore.flashDetails$.pipe(skip(1), take(1)).subscribe((flashDetails) => {
      this.userIsFlash = flashDetails.flashProgramAvailable;
    });
    this.settingsStore.settings$
      .pipe(filter(Boolean), takeUntil(this.onDestroy$))
      .subscribe((settings: Settings) => {
        if (settings.user) {
          this.user = settings.user;
          if (this.user.companies && this.user.companies[0]) {
            this.companyName = this.user.companies[0].name;
          }
        }

        this.salesClaimStore.currentUnclaimedSalesCount$
          .pipe(skip(1), takeUntil(this.onDestroy$))
          .subscribe((result) => {
            this.countSalesToClaim = result.count;
            this.hasSalesToClaim = result.count > 0;
          });
        this.salesClaimStore.getUserUnclaimedSalesCount();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  logout() {
    this.authenticationService
      .signOut()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }

  async switchBack() {
    this.authenticationService.stopImpersonation();

    window.location.reload();
  }

  redirectToSystems(): void {
    const systemRedirectUrl = `${this.systemsDetails.url}/auth/redirect-from-cloud?kyiSystemsUserId=${this.systemsDetails.userId}`;

    const win = window.open(systemRedirectUrl, '_self');

    win.focus();
  }

  openUnseenNotifications(): void {
    if (this.notificationStore.notifications?.length > 0) {
      const notification = this.notificationStore.notifications[0];

      this.openNotification(notification);
    }
  }

  openNotification(notification: Notification): void {
    this.dialog
      .openNotification([notification.message], this.translate.instant('Notification'))
      .finally(() => {
        this.notificationStore.setNotificationDisplayed(notification.id).then((success) => {
          if (success) {
            this.openUnseenNotifications();
          }
        });
      });
  }
}
