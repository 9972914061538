<div class="header">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <img
          class="logo"
          alt=""
          src="../../../assets/images/IBM-Power-Rewards-logo_BlackBgrd_RGB.png"
        />
      </div>
      <div class="col-sm-8 login-container"></div>
    </div>
  </div>
</div>
<div class="middle">
  <div class="container">
    <div class="col-md-12 col-sm-12 col-xs-12 message-container">
      <div class="message">Logging in. Please wait...</div>
      <div>
        <i class="fas fa-spinner fa-spin fa-3x"></i>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-1">
        <img
          class="mmi-logo"
          src="https://kyisite-assets.s3.eu-west-2.amazonaws.com/images/mmi-logo.png"
          alt=""
        />
      </div>
      <div class="col-sm-9">
        <p>Part of www.ibm.com/partnerworld/knowyouribm</p>
        <p>
          IBM, the IBM logo, are trademarks of IBM Corp registered in many jurisdictions worldwide.
          A current list of IBM trademarks is available on the Web at "Copyright and trademark
          information" at www.ibm.com/legal/copytrade.shtml (US) . Other company, products and
          service marks may be trade marks or service marks of others. Copyright IBM
        </p>
      </div>
      <div class="col-sm-2">
        <img
          class="ibm-logo"
          src="https://kyisite-assets.s3.eu-west-2.amazonaws.com/images/ibm-logo-white.png"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
