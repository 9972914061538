<app-default-layout
  [secondLevelNavItems]="[
    { url: '/claim-a-sale', label: 'IBM Power Rewards' | translate },
    { url: '/red-hat', label: 'IBM Power Red Hat Rewards' | translate, active: true }
  ]"
>
  <div class="container content content-bottom-border">
    <div class="row">
      <div class="col-lg-3 col-md-12 side-content">
        <p>
          <strong>{{ 'Eligible sales' | translate }}</strong>
        </p>
        <div class="mt-20">
          <a class="btn btn-primary w-100" [routerLink]="'/previously-claimed-invoices'">
            {{ 'Previously claimed invoices' | translate }}
            <span class="arrow"><img src="../../../../assets/images/arrow-right-white.svg" /></span>
          </a>
        </div>
        <div class="mt-30 eligible-invoices-wrapper">
          <div class="d-flex align-items-center quarter-header-wrapper">
            <span>{{ 'Eligible invoices' | translate }}</span>
          </div>
          <div class="d-flex flex-column quarter-wrapper">
            <span class="quarter-wrapper-normal"><strong>Q3 Jul 1 - Sept 30, 2022</strong></span>
            <span class="quarter-wrapper-small">Can be claimed until Dec 31, 2022</span>
          </div>
          <div class="d-flex flex-column quarter-wrapper">
            <span class="quarter-wrapper-normal"><strong>Q4 Oct 1 - Dec 31, 2022</strong></span>
            <span class="quarter-wrapper-small">Can be claimed until Mar 31, 2023</span>
          </div>
        </div>
      </div>

      <div class="col-lg-9 col-md-12">
        <div class="form-container mb-20">
          <div class="mb-20 mt-20" [innerHtml]="body"></div>
          <form [formGroup]="redHatForm" (ngSubmit)="onSubmit()">
            <div class="row" *ngIf="isPrc">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Select a user' | translate }}: *</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="userId"
                    [ngbTypeahead]="userTypeahead"
                    [resultFormatter]="formatUsername"
                    [inputFormatter]="formatUsername"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Machine Type Model of eligible Power product' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="machineTypeModel"
                    type="text"
                    style="width: 100%"
                    placeholder="Machine Type Model"
                  />
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Serial # of eligible Power product' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="serialNumber"
                    type="text"
                    style="width: 100%"
                    placeholder="Serial #"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Order date of eligible Power product' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="orderDate"
                    type="date"
                    style="width: 100%"
                    placeholder="Order date"
                  />
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Deal Registration #' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="dealRegistrationNumber"
                    type="text"
                    style="width: 100%"
                    placeholder="Deal Registration #"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'End Client Name' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="endCustomerName"
                    type="text"
                    style="width: 100%"
                    placeholder="End Client Name"
                  />
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Transaction date of Red Hat' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="transactionDateRedHat"
                    type="date"
                    style="width: 100%"
                    placeholder="Transaction date of Red Hat"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Revenue' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="revenue"
                    type="text"
                    style="width: 100%"
                    placeholder="Revenue"
                  />
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Purchased through' | translate }}: *</label>
                  <select class="form-control" formControlName="invoiceCopy">
                    <option value="Purchased through eConfig">
                      {{ 'Purchased through eConfig' | translate }}
                    </option>
                    <option value="Purchased through PPA">
                      {{ 'Purchased through PPA' | translate }}
                    </option>
                    <option value="3rd Party Invoice">{{ '3rd Party Invoice' | translate }}</option>
                  </select>
                  <div *ngIf="redHatForm.get('invoiceCopy').value === '3rd Party Invoice'">
                    <label>{{ 'Upload the invoice' | translate }}: *</label>
                    <input
                      class="form-control"
                      type="file"
                      style="width: 100%"
                      placeholder="Upload the invoice"
                      (change)="handleInvoiceUpload($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 col-sm-12">
                <button
                  class="btn btn-primary"
                  type="submit"
                  [disabled]="submitting ? 'disabled' : null"
                >
                  {{ 'Submit' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="container content mobile-content">
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content" [innerHtml]="sectionTwoSideContent"></div>
      <div class="d-none d-lg-block col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-md-9 col-sm-12">
        <div class="mb-20" [innerHtml]="sectionTwoMainContent"></div>

        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="link-box">
              <div class="title">Eligible products</div>
              <div class="text" [innerHtml]="bottomLeftBoxContent"></div>
              <div class="icon">
                <i class="far fa-file-download"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="link-box">
              <div class="title">Terms</div>
              <div class="text" [innerHtml]="bottomMiddleBoxContent"></div>
              <div class="icon">
                <i class="fas fa-external-link"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="link-box">
              <div class="title">Help?</div>
              <div class="text" [innerHtml]="bottomRightBoxContent"></div>
              <div class="icon">
                <i class="far fa-envelope"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
