<app-default-layout [title]="'<h1>Pre-sale activities</h1>'" [showBackToLanding]="true">
  <div class="container content">
    <div class="row">
      <div class="col-md-3 side-content">
        <div class="d-none d-lg-block" [innerHtml]="sideContent"></div>
        <a
          class="btn btn-outline-primary pt-10 mt-25"
          [routerLink]="['/previous-pre-sale-activities']"
        >
          {{ 'Previous pre-sales activities' | translate }}
          <span class="arrow"><img src="../../../../assets/images/arrow-right-blue.svg" /></span>
        </a>
      </div>
      <div class="col-md-9">
        <div [innerHtml]="bodyContent"></div>
        <div class="title">Complete your pre-sales activities below.</div>
        <ps-meeting-form></ps-meeting-form>
        <ps-assessment-form></ps-assessment-form>

        <div class="note-text" [innerHtml]="disclaimer"></div>
        <div class="row">
          <div class="col-md-4">
            <div class="link-box">
              <div class="title">Eligible products</div>
              <div class="text" [innerHtml]="bottomLeftBoxContent"></div>
              <div class="icon">
                <i class="far fa-file-download"></i>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="link-box">
              <div class="title">Terms</div>
              <div class="text" [innerHtml]="bottomMiddleBoxContent"></div>
              <div class="icon">
                <i class="fas fa-external-link"></i>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="link-box">
              <div class="title">Help?</div>
              <div class="text" [innerHtml]="bottomRightBoxContent"></div>
              <div class="icon">
                <i class="far fa-envelope"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
