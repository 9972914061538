import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MeetingFormComponent } from './meeting-form/meeting-form.component';
import { AssessmentFormComponent } from './assessment-form/assessment-form.component';
import { PreviouslySubmittedMeetingsComponent } from './previously-submitted-meetings/previously-submitted-meetings.component';
import { GenericListModule } from '@motivforce/mx-library-angular';
import { PreviouslySubmittedAssessmentsComponent } from './previously-submitted-assessments/previously-submitted-assessments.component';
import { PresalesFormsComponent } from './presales-page/presales-forms.component';
import { PreviouslySubmittedActivitiesPageComponent } from './previously-submitted-activities-page/previously-submitted-activities-page.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PresalesConfirmationComponent } from './presales-confirmation/presales-confirmation.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    MeetingFormComponent,
    AssessmentFormComponent,
    PreviouslySubmittedMeetingsComponent,
    PreviouslySubmittedAssessmentsComponent,
    PresalesFormsComponent,
    PreviouslySubmittedActivitiesPageComponent,
    PresalesConfirmationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    GenericListModule,
    SharedModule,
    RouterModule,
    NgbTypeaheadModule,
  ],
  exports: [
    MeetingFormComponent,
    AssessmentFormComponent,
    PreviouslySubmittedMeetingsComponent,
    PreviouslySubmittedAssessmentsComponent,
  ],
})
export class PreSalesModule {}
