import { DefaultTemplateComponent } from '../layout/default-template/default-template.component';
import { Type } from '@angular/core';
import { ArticleListPageComponent } from '../layout/article-list-page/article-list-page.component';
import { ArticleDetailComponent } from '../layout/article-detail/article-detail.component';
import { ContactFormPageComponent } from '../layout/contact-form-page/contact-form-page.component';
import { VideoPageComponent } from '../layout/video-page/video-page.component';
import { HomeComponent } from '../home/components/home.component';
import { MemberProfilePageComponent } from '../user/member-profile-page/member-profile-page.component';
import { FaqPageComponent } from '../support/faq-page/faq-page.component';
import { LandingPageComponent } from '../layout/landing-page/landing-page.component';
import { MastercardLandingPageComponent } from '../mastercard/landing/landing.component';
import { IdentificationComponent } from '../mastercard/identification/identification.component';
import { UploadDocumentComponent } from '../mastercard/upload-document/upload-document.component';
import { ActivateCardComponent } from '../mastercard/activate-card/activate-card.component';
import { TransactionsComponent } from '../mastercard/transactions/transactions.component';
import { ViewPinComponent } from '../mastercard/view-pin/view-pin.component';
import { ReportLostComponent } from '../mastercard/report-lost/report-lost.component';
import { RequestReplacementComponent } from '../mastercard/request-replacement/request-replacement.component';
import { PrivacyPolicyPageComponent } from '../layout/privacy-policy-page/privacy-policy-page.component';
import { TermsAndConditionsPageComponent } from '../layout/terms-and-conditions-page/terms-and-conditions-page.component';
import { BlockedPageComponent } from '../user/blocked-page/blocked-page.component';
import { CompanyDetailsComponent } from '../user/company-details/company-details.component';
import { ChooseCompanyComponent } from '../user/choose-company/choose-company.component';
import { AccountStatementPageComponent } from '../account/account-statement-page/account-statement-page.component';
import { SaleClaimPageComponent } from '../sales-claim/sale-claim-page/sale-claim-page.component';
import { PresalesFormsComponent } from '../presales/presales-page/presales-forms.component';
import { PreviouslySubmittedActivitiesPageComponent } from '../presales/previously-submitted-activities-page/previously-submitted-activities-page.component';
import { PreviouslyClaimedPageComponent } from '../sales-claim/previously-claimed-page/previously-claimed-page.component';
import { RedHatPageComponent } from '../sales-claim/red-hat-page/red-hat-page.component';
import { PayTmContentTemplateComponent } from '../pay-tm/components/pay-tm-content-template/pay-tm-content-template.component';
import { PayTmValidationContentTemplateComponent } from '../pay-tm/components/pay-tm-validation-content-template/pay-tm-validation-content-template.component';
import { CompanyIsFirmComponent } from '../user/company-is-firm/company-is-firm/company-is-firm.component';

const mapper = {
  homeComponent: HomeComponent as Type<any>,
  profilePage: MemberProfilePageComponent as Type<any>,
  articleListPage: ArticleListPageComponent as Type<any>,
  articleDetail: ArticleDetailComponent as Type<any>,
  faqPage: FaqPageComponent as Type<any>,
  contactUsPage: ContactFormPageComponent as Type<any>,
  landingPage: LandingPageComponent as Type<any>,
  mastercardLandingPageComponent: MastercardLandingPageComponent as Type<any>,
  mastercardIdentificationComponent: IdentificationComponent as Type<any>,
  mastercardUploadDocumentsPage: UploadDocumentComponent as Type<any>,
  mastercardActivationPage: ActivateCardComponent as Type<any>,
  mastercardTransactionsPage: TransactionsComponent as Type<any>,
  mastercardViewPin: ViewPinComponent as Type<any>,
  mastercardReportMissing: ReportLostComponent as Type<any>,
  mastercardRequestReplacement: RequestReplacementComponent as Type<any>,
  privacyPolicyPage: PrivacyPolicyPageComponent as Type<any>,
  termsAndConditionsPage: TermsAndConditionsPageComponent as Type<any>,
  ibmVideoPage: VideoPageComponent as Type<any>,
  blockedPage: BlockedPageComponent as Type<any>,
  companyDetailsPage: CompanyDetailsComponent as Type<any>,
  companyChoicePage: ChooseCompanyComponent as Type<any>,
  companyIsFirm: CompanyIsFirmComponent as Type<any>,
  accountStatementPage: AccountStatementPageComponent as Type<any>,
  saleClaimTemplate: SaleClaimPageComponent as Type<any>,
  presaleForms: PresalesFormsComponent as Type<any>,
  previouslySubmittedActivities: PreviouslySubmittedActivitiesPageComponent as Type<any>,
  previouslyClaimedTemplate: PreviouslyClaimedPageComponent as Type<any>,
  redHatPage: RedHatPageComponent as Type<any>,
  payTmContent: PayTmContentTemplateComponent as Type<any>,
  payTmValidation: PayTmValidationContentTemplateComponent as Type<any>,
  defaultTemplate: DefaultTemplateComponent as Type<any>,
  default: DefaultTemplateComponent as Type<any>, // fallback if no component can be found
};

export default mapper;
