import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutService } from '../layout.service';
import { Content } from '../../models/content.model';
import { SettingsProvider } from '../../shared/settings.provider';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../user/user.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-and-conditions-page',
  templateUrl: './terms-and-conditions-page.component.html',
  styleUrls: ['./terms-and-conditions-page.component.scss'],
})
export class TermsAndConditionsPageComponent implements OnInit, OnDestroy {
  baseContent: Content;
  settingsSubscription: Subscription;

  body: SafeHtml;
  accepted: boolean;
  updating = false;

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private settingsProvider: SettingsProvider,
    private userService: UserService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = this.sanitizer.bypassSecurityTrustHtml(bodyField.value.value);

    this.settingsSubscription = this.settingsProvider.settingsChanged.subscribe(() => {
      this.accepted = this.settingsProvider.settings.data.user.acceptedTermsConditions;
    });

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  ngOnDestroy(): void {
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }

  accept(): void {
    this.updating = true;
    this.userService
      .acceptTermsAndConditions()
      .then(() => this.settingsProvider.refreshUserSettings())
      .then(() => this.router.navigate(['/']))
      .finally(() => (this.updating = false));
  }
}
