import { Injectable } from '@angular/core';
import { BaseCoreService } from '../shared/base-core-service';
import { DateSearch } from '../models/search/date-search';
import { Observable } from 'rxjs';
import {
  PointsSummary,
  YearPointsSummary,
  AccountStatement,
  YearRequirementList,
} from './points-summary-models';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseCoreService {
  constructor(protected apiRestService: ApiRestService) {
    super('account', apiRestService);
  }

  async getSalesPointsSearchResults(searchContext: DateSearch): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/power/sales-account-statement-search`;

    return await this.apiRestService.post<any>(endpointUrl, searchContext).toPromise();
  }

  async getPreSalesPointsSearchResults(searchContext: DateSearch): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/power/presales-account-statement-search`;

    return await this.apiRestService.post<any>(endpointUrl, searchContext).toPromise();
  }

  getPointsSummary(): Promise<PointsSummary> {
    const endpointUrl = `${this.memberBaseUrl}/points/summary`;
    return this.apiRestService.get<PointsSummary>(endpointUrl).toPromise();
  }

  getYearSummaries(): Observable<Array<YearPointsSummary>> {
    const endpointUrl = `${this.memberBaseUrl}/points/year-summaries`;
    return this.apiRestService.get<Array<YearPointsSummary>>(endpointUrl);
  }

  getAccountStatement(): Promise<AccountStatement> {
    const endpointUrl = `${this.memberBaseUrl}/flash/account-statement-search`;
    return this.apiRestService.get<AccountStatement>(endpointUrl).toPromise();
  }

  getYearRequirements(): Promise<YearRequirementList[]> {
    const endpointUrl = `${this.memberBaseUrl}/year-requirements`;
    return this.apiRestService.get<YearRequirementList[]>(endpointUrl).toPromise();
  }
}
