import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { Settings } from 'src/app/entities/settings';
import { LayoutService } from 'src/app/layout/layout.service';
import { NavItem } from 'src/app/models/nav-item';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  @Input() bannerUrl: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() showBackToLanding: boolean = false;
  @Input() showTopNav: boolean = true;
  @Input() showLowerNav: boolean = true;
  @Input() showLogo: boolean = true;
  @Input() showButton: boolean = false;
  @Input() showMastercardHeader: boolean = false;
  @Input() currentMastercardPage: string;
  @Input() bigBanner: boolean = false;
  @Input() mediumBanner: boolean = false;
  @Input() secondLevelNavItems: NavItem[];

  showBanner: boolean = true;
  displayedTitle: string;
  displayedSubTitle: string;
  displayedBannerUrl: string;

  constructor(
    private layoutService: LayoutService,
    private settingsStore: SettingsStoreService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.layoutService.banner.subscribe((banner) => {
      if (banner || (this.title && this.bannerUrl)) {
        this.displayedTitle = this.title || banner?.title;
        this.displayedBannerUrl = this.bannerUrl || banner?.imageUrl;
        this.displayedSubTitle = this.subTitle || banner?.subTitle;
        this.showBanner = true;
      } else if (!this.title && !this.bannerUrl) {
        this.showBanner = false;
      }
    });
  }

  salesButtonClicked(): void {
    this.router.navigate(['/claim-a-sale']);
  }

  technicalButtonClicked(): void {
    this.router.navigate(['/pre-sale-activities']);
  }
}
