<div class="top-nav">
  <nav class="navbar navbar-expand-md layout-boxed">
    <ul class="navbar-nav ml-auto">
      <!-- <li class="nav-item" *ngIf="!impersonate && systemsDetails?.url">
        <button class="btn btn-primary btn-sm mt-5 mr-5" type="button" (click)="redirectToSystems()">
          Switch to KYI site
        </button>
      </li> -->
      <li class="d-none d-lg-block nav-item" *ngIf="impersonate">
        <button
          class="btn btn-primary btn-sm mt-5 mr-5"
          type="button"
          style="max-height: 27px"
          (click)="switchBack()"
        >
          Switch Back to Admin User
        </button>
      </li>
      <li class="d-none d-lg-block nav-item">
        <a
          class="nav-link"
          *ngIf="user"
          [class.notification-bold]="hasSalesToClaim"
          routerLink="/claim-a-sale"
        >
          <span class="notification-dot" *ngIf="hasSalesToClaim">{{ countSalesToClaim }}</span>
          {{ 'Sales' | translate }}
        </a>
      </li>
      <li class="d-none d-lg-block nav-item">
        <p class="navbar-text mr-15" *ngIf="user">
          {{ 'Hello' | translate }} {{ user.firstName }} {{ user.lastName }}, {{ companyName }}
        </p>
      </li>
      <!-- <li class="nav-item" *ngIf="canLearn">
        <p class="navbar-text mr-10">
          {{ 'Total Available Points' | translate }}: {{ pointsSummary?.availableBalance }}
        </p>
      </li> -->
      <!-- <li *ngIf="userIsSales" class="nav-item">
        <div class="navbar-text mr-5 ml-5">
          <lib-notification-button
            [newNotification]="(notifications$ | async)?.length > 0"
            [disabled]="!((notifications$ | async)?.length > 0)"
            (open)="openUnseenNotifications()"
          ></lib-notification-button>
        </div>
      </li> -->

      <li class="d-none d-lg-block nav-item" *ngIf="showKyi">
        <a class="navbar-text mr-15 nav-link-underline" href="https://kyirewards.com"
          >Switch to KYI Rewards</a
        >
      </li>
      <li class="d-none d-lg-block nav-item" *ngIf="showFlash">
        <a class="navbar-text mr-15 nav-link-underline" href="https://flash.kyirewards.com"
          >Switch to Flash Rewards</a
        >
      </li>

      <!-- <div class="col-sm-6 text-right">
        <a [routerLink]="'/landing'">Back to Landing</a>
        &nbsp; | &nbsp;
        <a href="https://kyirewards.com">Switch to KYI Site</a>
      </div> -->

      <li class="d-none d-lg-block nav-item">
        <a class="navbar-text mr-15" (click)="logout()">{{ 'Log out' | translate }}</a>
      </li>
    </ul>
  </nav>
</div>
