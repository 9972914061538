import { Injectable } from '@angular/core';
import { BaseCoreService } from '../shared/base-core-service';
import { ApiRestService } from '@motivforce/mx-library-angular';
import { ConfirmationDetailsInput } from '../models/confirmation-details-input';

@Injectable({
  providedIn: 'root',
})
export class SupportService extends BaseCoreService {
  constructor(protected apiRestService: ApiRestService) {
    super('support', apiRestService);
  }
}
