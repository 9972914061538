import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutRoutes } from './layout/layout-routing';
import { AuthGuard } from './authentication/auth-gaurd';
import { NotFoundErrorComponent } from './error/not-found-error/not-found-error.component';
import { PermissionErrorComponent } from './error/permission-error/permission-error.component';
import { ServerErrorComponent } from './error/server-error/server-error.component';
import { ErrorModule } from './error/error.module';
import { UnauthGuard } from './authentication/unauth-gaurd';
import { DynamicLayoutComponent } from './layout/dynamic-layout/dynamic-layout.component';
import { PresalesConfirmationComponent } from './presales/presales-confirmation/presales-confirmation.component';
import { MoreNavComponent } from './shared/layout/more-nav/more-nav/more-nav.component';

const routes: Routes = [
  {
    path: 'pre-sales-confirmation',
    component: PresalesConfirmationComponent,
  },
  {
    path: 'competitive-displace-confirmation',
    component: PresalesConfirmationComponent,
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'more',
    component: MoreNavComponent,
  },
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'signin',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: NotFoundErrorComponent,
  },
  {
    path: '401',
    component: PermissionErrorComponent,
  },
  {
    path: '500',
    component: ServerErrorComponent,
  },
  // {
  //   path: 'product-catalogue',
  //   loadChildren: () =>
  //     import('./product-catalogue/product-catalogue.module').then((m) => m.ProductCatalogueModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'refer-a-friend-received',
    component: DynamicLayoutComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: '',
    children: LayoutRoutes,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' }),
    ErrorModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
