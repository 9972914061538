<div class="main-wrapper">
  <form [formGroup]="formGroup">
    <div class="form-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="form-title">
            <b>Technical Seller:</b> Assessment (Security, Cyber, Storage), or Onsite/Virtual POC,
            Trial, Benchmark.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="month">{{ 'Select month' | translate }}:</label>
            <select class="form-control" #monthSelect formControlName="month">
              <option [ngValue]="null">{{ 'Choose an option' | translate }}</option>
              <option class="option" *ngFor="let month of months" [ngValue]="month.id">
                {{ month.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
            <label>{{ 'Find customer' | translate }}:</label>
            <input
              class="form-control"
              type="text"
              style="width: 100%"
              formControlName="endCustomerName"
              placeholder="Full name"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="h-100 mb-20 d-flex align-items-end">
            <button
              class="w-100 btn btn-primary search-button btn-card"
              type="submit"
              (click)="searchAssessments()"
            >
              {{ 'Search' | translate }}
              <img src="../../../assets/images/Search.png" style="width: 14px; height: auto" />
            </button>
          </div>
        </div>
        <div class="col-md-2">
          <div class="h-100 mb-20 d-flex align-items-end">
            <button class="btn btn-link search-button btn-card" type="reset">
              {{ 'Reset' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="row mb-30">
    <div class="col-md-12">
      <div class="d-none d-md-block">
        <lib-generic-list
          [dataProvider]="dataProvider"
          [totalCount]="resultsLength"
          [columnsProvider]="assessmentListColumnsProvider"
          [noResultsMessageDisplayKey]="'&nbsp;&nbsp;No client assessments found'"
          [serverPaging]="true"
          [tableStriped]="false"
          [actionsColumnLeft]="false"
          (stateChanged)="onListStateChanged($event)"
        >
        </lib-generic-list>
      </div>

      <div class="d-block d-sm-none">
        <div *ngFor="let row of dataProvider">
          <div *ngFor="let column of assessmentListColumnsProvider">
            <div class="col-sm-12">
              <p>
                <strong>{{ column.columnNameDisplayKey }}: </strong>
                <span
                  *ngIf="
                    column.labelFunction &&
                    column.fieldName !== 'detail' &&
                    column.fieldName !== 'action'
                  "
                  >{{
                    row[column.fieldName]
                      | date
                        : (settingsStore.settings?.user?.program?.dateFormat
                            ? settingsStore.settings?.user?.program?.dateFormat
                            : 'dd-MM-yyyy')
                  }}</span
                >
                <span
                  *ngIf="
                    !column.labelFunction &&
                    column.fieldName !== 'detail' &&
                    column.fieldName !== 'action'
                  "
                  >{{ row[column.fieldName] }}</span
                >
              </p>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>
