<app-default-layout
  [secondLevelNavItems]="
    !hideSubmenu && [
      { url: '/claim-a-sale', label: 'IBM Power Rewards' | translate, active: true },
      { url: '/red-hat', label: 'IBM Power Red Hat Rewards' | translate }
    ]
  "
>
  <div class="container content content-bottom-border">
    <div class="row">
      <div class="col-lg-3 col-md-12 side-content">
        <p>
          <strong>{{ 'Eligible sales' | translate }}</strong>
        </p>
        <div class="mt-30">
          <button class="btn btn-primary w-100" type="button" (click)="openMissingInvoice()">
            {{ 'Missing invoice' | translate }}
            <span class="arrow"><img src="../../../../assets/images/arrow-right-white.svg" /></span>
          </button>
        </div>
        <div class="mt-20">
          <a class="btn btn-primary w-100" [routerLink]="'/previously-claimed-invoices'">
            {{ 'Previously claimed invoices' | translate }}
            <span class="arrow"><img src="../../../../assets/images/arrow-right-white.svg" /></span>
          </a>
        </div>
        <div class="mt-30 eligible-invoices-wrapper">
          <div class="d-flex align-items-center quarter-header-wrapper">
            <span>{{ 'Eligible invoices' | translate }}</span>
          </div>
          <div class="d-flex flex-column quarter-wrapper">
            <span class="quarter-wrapper-normal"><strong>Q3 Jul 1 - Sept 30, 2022</strong></span>
            <span class="quarter-wrapper-small">Can be claimed until Dec 31, 2022</span>
          </div>
          <div class="d-flex flex-column quarter-wrapper">
            <span class="quarter-wrapper-normal"><strong>Q4 Oct 1 - Dec 31, 2022</strong></span>
            <span class="quarter-wrapper-small">Can be claimed until Mar 31, 2023</span>
          </div>
        </div>
      </div>

      <div class="col-lg-9 col-md-12">
        <div class="mb-20 mt-20" [innerHtml]="competitiveDisplacementContent"></div>
        <div class="form-container mb-20">
          <div class="mb-20 mt-20 pt-20">
            <h2>How do I submit for a Competitive Displace?</h2>
            <p>
              Please complete the ‘Competitive Displace’ form below.  Upon confirmation by IBM, we
              will confirm and advise the sale is ready to claim.  Once claimed the funds will be
              auto-loaded to your IBM Rewards Card.
            </p>
          </div>
          <form [formGroup]="competitiveDisplaceForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Business partner name' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="bPartnerFullName"
                    type="text"
                    style="width: 100%"
                    placeholder="Business partner name"
                  />
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Business partner email address' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="bPartnerEmail"
                    type="text"
                    style="width: 100%"
                    placeholder="Business partner email address"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Company name' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="companyName"
                    type="text"
                    style="width: 100%"
                    placeholder="Company name"
                  />
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Deal Registration #' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="dealRegistrationNumber"
                    type="text"
                    style="width: 100%"
                    placeholder="Deal Registration #"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Model number' | translate }}</label>
                  <select class="form-control" formControlName="machineTypeModel">
                    <option value="">{{ 'Select' | translate }}</option>
                    <option *ngFor="let type of machineTypes" [value]="type.name">
                      {{ type.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Serial Number' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="serialNumber"
                    type="text"
                    style="width: 100%"
                    placeholder="Serial Number"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'End Customer Name' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="endCustomerName"
                    type="text"
                    style="width: 100%"
                    placeholder="End customer name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 col-sm-12">
                <button
                  class="btn btn-primary"
                  type="submit"
                  [disabled]="submitting ? 'disabled' : null"
                >
                  {{ 'Submit' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="form-container mb-20">
          <div class="mb-20 mt-20 pt-20">
            <h2>
              Not sure if your potential client is NEW and has no Power8, Power9 or Power10 systems
              installed as indicated by IBM’s install base data?
            </h2>
            <p>Complete the fields below and we will confirm&hellip;</p>
          </div>
          <form [formGroup]="newCustomerForm" (ngSubmit)="onSubmitNewCustomer()">
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Name of customer' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="companyName"
                    type="text"
                    style="width: 100%"
                    placeholder="Customer name"
                  />
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Country' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="country"
                    type="text"
                    style="width: 100%"
                    placeholder="Name of country"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 col-sm-12">
                <button class="btn btn-primary" type="submit">
                  {{ 'Submit' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="mb-20 mt-20" [innerHtml]="sectionOneMainContent"></div>
        <sales-claim-list
          [charityDonationDialogContent]="charityDonationDialogContent"
          (viewDetails)="viewDetails($event)"
        ></sales-claim-list>
      </div>
    </div>
  </div>
  <div class="container content mobile-content">
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content" [innerHtml]="sectionTwoSideContent"></div>
      <div class="d-none d-lg-block col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-md-9 col-sm-12">
        <div class="mb-20" [innerHtml]="sectionTwoMainContent"></div>

        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="link-box">
              <div class="title">Eligible products</div>
              <div class="text" [innerHtml]="bottomLeftBoxContent"></div>
              <div class="icon">
                <i class="far fa-file-download"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="link-box">
              <div class="title">Terms</div>
              <div class="text" [innerHtml]="bottomMiddleBoxContent"></div>
              <div class="icon">
                <i class="fas fa-external-link"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="link-box">
              <div class="title">Help?</div>
              <div class="text" [innerHtml]="bottomRightBoxContent"></div>
              <div class="icon">
                <i class="far fa-envelope"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
