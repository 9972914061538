<app-default-layout [showMastercardHeader]="true" [currentMastercardPage]="'Activate'">
  <div class="container content">
    <div class="row">
      <div class="d-none d-lg-block col-lg-2 col-md-12 side-content" [innerHtml]="sideTitle"></div>
      <div class="d-none d-lg-block col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12 form-box">
        <div *ngIf="!yearRequirementsComplete">
          <p>
            You must have met all the eligibility criteria before you can activate your IBM Rewards
            Card.
          </p>

          <p>
            Please visit <a [routerLink]="'/auth/my-profile'">Manage my profile</a> to check what is
            missing.
          </p>

          <p>
            If you need further support please contact us at
            <a href="mailto:power@kyirewards.com">power@kyirewards.com</a>
          </p>
        </div>
        <div *ngIf="yearRequirementsComplete">
          <form [formGroup]="activationForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-sm-12">
                <h2>{{ 'Activate card' | translate }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-sm-12" [innerHtml]="body"></div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Card number' | translate }} *</label>
                  <input class="form-control" type="text" formControlName="cardNumber" />
                </div>
              </div>

              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Activation code' | translate }} *</label>
                  <input class="form-control" type="text" formControlName="activationCode" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-sm-12">
                <button type="submit" class="btn btn-primary">
                  {{ 'Activate Account' | translate }}
                  <img src="../../../../assets/images/arrow-right-white.svg" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
