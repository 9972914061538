<div class="form-box">
  <form *ngIf="assessmentForm" [formGroup]="assessmentForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-12">
        <div class="form-title">
          <b>Technical Seller:</b> Assessment (Security, Cyber, Storage), or Onsite/Virtual POC,
          Trial, Benchmark.
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isPrc">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Select a user' | translate }}:</label>
          <input
            class="form-control"
            type="text"
            formControlName="userId"
            [ngbTypeahead]="userTypeahead"
            [resultFormatter]="formatUsername"
            [inputFormatter]="formatUsername"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Date of assessment' | translate }}</label>
          <input
            class="form-control"
            formControlName="assessmentDate"
            type="date"
            [min]="programPeriod?.startDate?.substring(0, 10)"
            [max]="programPeriod?.endDate?.substring(0, 10)"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Name of customer' | translate }}</label>
          <input class="form-control" formControlName="customerName" type="text" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Deal registration #' | translate }}</label>
          <input class="form-control" formControlName="dealRegistrationNumber" type="text" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'IBM Sales Representative First name' | translate }}</label>
          <input class="form-control" formControlName="ibmFirstName" type="text" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'IBM Sales Representative Last name' | translate }}</label>
          <input class="form-control" formControlName="ibmLastName" type="text" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Email of IBM Sales Representative' | translate }}</label>
          <input class="form-control" formControlName="ibmEmail" type="email" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Assessment type' | translate }}</label>
          <select class="form-control" formControlName="assessmentTypeId">
            <option value="">{{ 'Select' | translate }}</option>
            <option *ngFor="let type of assessmentTypes" [value]="type.id">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-9 my-auto">
        {{
          'Upon submission a triggered mail will be sent requesting confirmation of the meet.'
            | translate
        }}
        <p>*Upon confirmation $ will be auto loaded to your IBM Rewards Card.</p>
      </div>
      <div class="col-md-3">
        <button type="submit" class="btn btn-primary submit" [disabled]="submitting">
          {{ 'Submit' | translate }} <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </form>
</div>
