<div class="header">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <img class="logo" alt="" src="assets/images/ibm-logo.png" />
      </div>
    </div>
  </div>
</div>
<div class="container content content-bottom-border">
  <form [formGroup]="rejectionForm" (ngSubmit)="onSubmit()" *ngIf="showRejectionForm">
    <div class="row">
      <div class="col-md-12">
        <div class="form-title">{{ 'Please specify a rejection reason:' | translate }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="radio">
            <input
              type="radio"
              formControlName="reason"
              value="Deal Registration number provided has expired, or is invalid"
            />
            {{ 'Deal Registration number provided has expired, or is invalid' | translate }}
          </div>
          <div class="radio">
            <input
              type="radio"
              formControlName="reason"
              value="Client is considered to be a Government Owned Entity (GOE)"
            />
            {{ 'Client is considered to be a Government Owned Entity (GOE)' | translate }}
          </div>
          <div class="radio" *ngIf="!hideNewPowerClientOption">
            <input type="radio" formControlName="reason" value="Client is not a new Power client" />
            {{ 'Client is not a new Power client' | translate }}
          </div>
          <div class="radio">
            <input type="radio" formControlName="reason" value="" />
            {{ 'Other' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group" *ngIf="rejectionForm.value.reason === ''">
          <label>{{ 'Other reason' | translate }}</label>
          <textarea class="form-control" formControlName="otherReason" rows="3"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <button type="submit" class="btn btn-primary">{{ 'Submit' | translate }}</button>
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="approvalForm" (ngSubmit)="onApprovalSubmit()" *ngIf="showApprovalForm">
    <div class="row">
      <div class="col-md-12">
        <div class="form-title">{{ 'Please specify an approval reason:' | translate }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>{{ 'Reason' | translate }}</label>
          <textarea class="form-control" formControlName="reason" rows="3"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <button type="submit" class="btn btn-primary">{{ 'Submit' | translate }}</button>
        </div>
      </div>
    </div>
  </form>

  <div *ngIf="!showRejectionForm && !showApprovalForm">
    <h4>Thank you for confirming</h4>
  </div>
</div>
<div *ngIf="!showRejectionForm" class="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <p>
          <strong
            >Part of www.ibm.com/partnerworld/knowyouribm and
            www.ibm/partnerworld/flashforce</strong
          ><br />
          IBM, the IBM logo, are trademarks of IBM Corp registered in many jurisdictions worldwide.
          A current list of IBM trademarks is available on the Web at “Copyright and trademark
          information” at www.ibm.com/legal/copytrade.shtml (US) . Other company, products and
          service marks may be trade marks or service marks of others. Copyright IBM.
        </p>
      </div>
    </div>
  </div>
</div>
