import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Content } from '@motivforce/mx-library-angular';
import { UserStoreService } from 'src/app/core/store/user-store.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';

@Component({
  selector: 'app-pay-tm-validation-content-template',
  templateUrl: './pay-tm-validation-content-template.component.html',
  styleUrls: ['./pay-tm-validation-content-template.component.scss'],
})
export class PayTmValidationContentTemplateComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  body: string;
  validationString: string;

  constructor(
    private route: ActivatedRoute,
    private userService: UserStoreService,
    private layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });

    this.route.queryParams.pipe().subscribe((params: any) => {
      if (params.validation) {
        this.validationString = params.validation;
        this.validationString = this.validationString.split(' ').join('+');
      }
    });

    if (this.validationString) {
      const form = { validationString: this.validationString };
      this.userService.confirmPayTm(form);
    }

    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = bodyField ? bodyField.value.value : '';
  }
}
