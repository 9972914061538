import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewInit,
  Inject,
  Input,
} from '@angular/core';
import { Month } from '../sales-result';
import { DialogNgbService, GenericListColumn } from '@motivforce/mx-library-angular';
import {
  DatePipe,
  DOCUMENT,
  FormStyle,
  getLocaleMonthNames,
  TranslationWidth,
} from '@angular/common';
import { MxLoggerService } from '@motivforce/mx-library-angular';
import { SalesClaimStoreService } from '../../core/store/sales-claim-store.service';
import { Subject } from 'rxjs';
import { filter, skip, take, takeUntil } from 'rxjs/operators';
import { UserKyiSaleGroup } from '../../core/model/user/user-kyi-sale-group';
import { GenericListState } from '@motivforce/mx-library-angular/lib/generic-list/model/generic-list-state';
import { UserKyiSaleGroupSearch } from '../../core/model/user/user-kyi-sale-group-search';
import { UserKyiSaleAction } from '../../core/model/user/user-kyi-sale-action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { UserCompany } from 'src/app/models/user-company';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@Component({
  selector: 'sales-claim-list',
  templateUrl: './sales-claim-list.component.html',
  styleUrls: ['./sales-claim-list.component.scss'],
})
export class SalesClaimListComponent implements OnInit, OnDestroy, AfterViewInit {
  months: Month[] = [
    {
      id: 1,
      value: 'Jan',
    },
    {
      id: 2,
      value: 'Feb',
    },
    {
      id: 3,
      value: 'Mar',
    },
    {
      id: 4,
      value: 'Apr',
    },
    {
      id: 5,
      value: 'May',
    },
    {
      id: 6,
      value: 'Jun',
    },
    {
      id: 7,
      value: 'Jul',
    },
    {
      id: 8,
      value: 'Aug',
    },
    {
      id: 9,
      value: 'Sep',
    },
    {
      id: 10,
      value: 'Oct',
    },
    {
      id: 11,
      value: 'Nov',
    },
    {
      id: 12,
      value: 'Dec',
    },
  ];

  companies: UserCompany[];
  primaryCompany: UserCompany;

  formGroup: FormGroup = this.formBuilder.group({
    month: [null],
    endCustomerName: [null],
    company: [null],
  });

  saleGroupSearch: UserKyiSaleGroupSearch = {
    month: null,
    endCustomerName: null,
    claimStatus: 'Unclaimed',
    pageSize: 10,
    pageNumber: 1,
    orderBy: null,
  };

  resultsLength: number;
  dataProvider: UserKyiSaleGroup[];

  saleListColumnsProvider: GenericListColumn[] = [
    {
      columnNameDisplayKey: 'Incentive',
      sortable: false,
      fieldName: 'incentiveName',
    },
    {
      columnNameDisplayKey: 'End Customer Name',
      sortable: false,
      fieldName: 'endCustomerName',
      width: '18%',
    },
    {
      columnNameDisplayKey: 'Month',
      sortable: false,
      fieldName: 'minInvoiceGroupDate',
      labelFunction: (item: UserKyiSaleGroup) =>
        this.datePipe.transform(item.minInvoiceGroupDate, 'MMMM'),
      width: '120px',
    },
    {
      columnNameDisplayKey: 'Detail',
      sortable: false,
      fieldName: 'detail',
      width: '140px',
    },
    {
      columnNameDisplayKey: 'Click to Claim Sale',
      sortable: false,
      fieldName: 'action',
      width: '260px',
    },
  ];

  @Input() charityDonationDialogContent: string;
  @Output() viewDetails: EventEmitter<UserKyiSaleGroup> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private mxLogger: MxLoggerService,
    private salesClaimStore: SalesClaimStoreService,
    private translateService: TranslateService,
    private dialog: DialogNgbService,
    public settingsStore: SettingsStoreService,
    private userStore: UserStoreService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    const locale = this.translateService.currentLang
      ? this.translateService.currentLang.substring(
          0,
          this.translateService.currentLang.indexOf('-') > 0
            ? this.translateService.currentLang.indexOf('-')
            : undefined,
        )
      : this.translateService.defaultLang.substring(
          0,
          this.translateService.defaultLang.indexOf('-') > 0
            ? this.translateService.defaultLang.indexOf('-')
            : undefined,
        );

    this.months = getLocaleMonthNames(locale, FormStyle.Standalone, TranslationWidth.Wide).map(
      (value, index) => {
        return { id: index + 1, value };
      },
    );

    this.salesClaimStore.currentSaleGroupSearchResult$
      .pipe(skip(1), takeUntil(this.onDestroy$))
      .subscribe((searchResult) => {
        this.resultsLength = searchResult.statistics.totalRecords;
        this.dataProvider = searchResult.results;

        this.updatePagingLabels();
      });

    this.settingsStore.settings$.pipe(filter(Boolean), takeUntil(this.onDestroy$)).subscribe(() => {
      this.companies = this.settingsStore.settings.user.companies;

      this.primaryCompany = this.companies.find((userCompany) => userCompany.isPrimary);

      if (this.primaryCompany) {
        this.formGroup.controls.company.setValue(this.primaryCompany);
      }
    });

    this.searchSalesClaims();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.updatePagingLabels();
  }

  updatePagingLabels(): void {
    const pageSizeBeforeElement = this.document.body.querySelector(
      '.page-options-size div:nth-child(1)',
    );
    pageSizeBeforeElement.innerHTML = 'Items per page:';

    console.log('ngAfterViewInit() pageSizeBeforeElement=', pageSizeBeforeElement);

    const pageSizeAfterElement = this.document.body.querySelector(
      '.page-options-size div:nth-child(3)',
    );

    pageSizeAfterElement.innerHTML = this.resultsLength
      ? (this.saleGroupSearch.pageNumber - 1) * this.saleGroupSearch.pageSize +
        1 +
        '-' +
        this.saleGroupSearch.pageNumber * this.saleGroupSearch.pageSize +
        ' of ' +
        this.resultsLength +
        ' items.'
      : '';
  }

  searchSalesClaims(): void {
    const saleGroupSearch = { ...this.saleGroupSearch, ...this.formGroup.value };

    this.salesClaimStore.searchUserSaleGroups(saleGroupSearch);
  }

  reset(): void {
    this.formGroup.reset();
  }

  onListStateChanged(event: GenericListState): void {
    this.saleGroupSearch.orderBy =
      event.sortColumn && event.sortColumn !== ''
        ? {
            fields: [
              { sortBy: event.sortColumn, sortDirection: event.sortDirection as 'asc' | 'desc' },
            ],
          }
        : null;

    this.saleGroupSearch.pageSize = event.pageSize;
    this.saleGroupSearch.pageNumber = event.page;

    this.searchSalesClaims();
  }

  actionClick(claimAction: UserKyiSaleAction): void {
    this.mxLogger.trace('SalesClaimListComponent', 'actionClick() claimAction=', claimAction);

    this.salesClaimStore.currentSaleAction$
      .pipe(skip(1), take(1))
      .subscribe((saleAction: UserKyiSaleAction) => {
        // this.dialog.openFeatureDialog('charityDonationDialog', [
        //   saleAction,
        //   this.charityDonationDialogContent,
        // ]);
        this.searchSalesClaims();
      });

    this.salesClaimStore.doSaleAcion(claimAction);
  }

  changeCompany(): void {
    this.mxLogger.trace(
      'SalesClaimListComponent',
      'changeCompany() this.formGroup.value=',
      this.formGroup.value,

      this.userStore.setPrimaryCompany(this.formGroup.controls.company.value.id),
    );
  }
}
