import { Injectable } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';
import { SearchResult } from 'src/app/models/search/search-result';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { MeetingType } from 'src/app/models/meeting-type';
import { PreSalesRestService } from '../api/rest/presales-rest.service';
import { AssessmentType } from 'src/app/models/assessment-type';
import { MeetingDetails } from 'src/app/models/meeting-details';
import { UserKyiMeetingGroup } from '../model/user/user-kyi-meeting-group';
import { UserKyiMeetingGroupSearch } from '../model/user/user-kyi-meeting-group-search';
import { MxLoggerService } from '@motivforce/mx-library-angular';
import { UserKyiAssessmentGroup } from '../model/user/user-kyi-assessment-group';
import { UserKyiAssessmentGroupSearch } from '../model/user/user-kyi-assessment-group-search';
import { Router } from '@angular/router';
import { ConfirmationDetailsInput } from 'src/app/models/confirmation-details-input';

@Injectable({
  providedIn: 'root',
})
export class PreSalesStoreService {
  constructor(
    private presalesRest: PreSalesRestService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogService,
    private mxLogger: MxLoggerService,
    private router: Router,
  ) {}

  private readonly _meetingTypes = new BehaviorSubject<MeetingType[]>(null);
  readonly meetingTypes$ = this._meetingTypes.asObservable();

  private readonly _assessmentTypes = new BehaviorSubject<AssessmentType[]>(null);
  readonly assessmentTypes$ = this._assessmentTypes.asObservable();

  private readonly _currentMeetingGroupSearchResult = new BehaviorSubject<
    SearchResult<UserKyiMeetingGroup>
  >(null);
  readonly currentMeetingGroupSearchResult$ = this._currentMeetingGroupSearchResult.asObservable();

  private readonly _currentAssessmentGroupSearchResult = new BehaviorSubject<
    SearchResult<UserKyiAssessmentGroup>
  >(null);
  readonly currentAssessmentGroupSearchResult$ =
    this._currentAssessmentGroupSearchResult.asObservable();

  get meetingTypes(): MeetingType[] {
    return this._meetingTypes.getValue();
  }

  get assessmentTypes(): AssessmentType[] {
    return this._assessmentTypes.getValue();
  }

  get currentMeetingGroupSearchResult(): SearchResult<UserKyiMeetingGroup> {
    return this._currentMeetingGroupSearchResult.value;
  }

  get currentAssessmentGroupSearchResult(): SearchResult<UserKyiAssessmentGroup> {
    return this._currentAssessmentGroupSearchResult.value;
  }

  getMeetingTypes(): void {
    this.isLoadingService.add(
      this.presalesRest.getMeetingTypes().subscribe((meetingTypes: MeetingType[]) => {
        this._meetingTypes.next(meetingTypes);
      }),
    );
  }

  submitMeetingDetails(meetingDetails: MeetingDetails): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.presalesRest.submitMeetingDetails(meetingDetails).subscribe(
          () => {
            this.dialog
              .openModal('Meeting details submitted successfully')
              .then(() => resolve(true));
          },
          () => {
            this.dialog.openModal('Meeting details submission failed').then(() => resolve(false));
          },
        ),
      );
    });
  }

  confirmMeetingDetails(details: ConfirmationDetailsInput): void {
    this.isLoadingService.add(
      this.presalesRest.confirmMeetingDetails(details).subscribe(
        () => {
          this.router.navigate(['/pre-sales-confirmation']);
          this.dialog.openModal('Meeting details updated successfully');
        },
        () => {
          this.router.navigate(['/pre-sales-confirmation']);
          this.dialog.openModal('Meeting details update failed');
        },
      ),
    );
  }

  getAssessmentTypes(): void {
    this.isLoadingService.add(
      this.presalesRest.getAssessmentTypes().subscribe((assessmentTypes: AssessmentType[]) => {
        this._assessmentTypes.next(assessmentTypes);
      }),
    );
  }

  submitAssessmentDetails(assessmentDetails: any): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.presalesRest.submitAssessmentDetails(assessmentDetails).subscribe(
          () => {
            this.dialog
              .openModal('Assessment details submitted successfully')
              .then(() => resolve(true));
          },
          () => {
            this.dialog
              .openModal('Assessment details submission failed')
              .then(() => resolve(false));
          },
        ),
      );
    });
  }

  confirmAssessmentDetails(details: ConfirmationDetailsInput): void {
    this.isLoadingService.add(
      this.presalesRest.confirmAssessmentDetails(details).subscribe(
        () => {
          this.router.navigate(['/pre-sales-confirmation']);
          this.dialog.openModal('Assessment details updated successfully');
        },
        () => {
          this.router.navigate(['/pre-sales-confirmation']);
          this.dialog.openModal('Assessment details update failed');
        },
      ),
    );
  }

  searchUserMeetingGroups(meetingGroupSearch: UserKyiMeetingGroupSearch): void {
    this.isLoadingService.add(
      this.presalesRest
        .searchUserMeetingGroups(meetingGroupSearch)
        .subscribe((searchResult: SearchResult<UserKyiMeetingGroup>) => {
          this.mxLogger.debug(
            'PreSalesStoreService',
            'searchUserMeetingGroups() searchResult=',
            searchResult,
          );

          this._currentMeetingGroupSearchResult.next(searchResult);
        }),
    );
  }

  searchUserAssessmentGroups(assessmentGroupSearch: UserKyiAssessmentGroupSearch): void {
    this.isLoadingService.add(
      this.presalesRest
        .searchUserAssessmentGroups(assessmentGroupSearch)
        .subscribe((searchResult: SearchResult<UserKyiAssessmentGroup>) => {
          this.mxLogger.debug(
            'PreSalesStoreService',
            'searchUserAssessmentGroups() searchResult=',
            searchResult,
          );

          this._currentAssessmentGroupSearchResult.next(searchResult);
        }),
    );
  }
}
