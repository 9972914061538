import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './components/home.component';
import { DefaultTemplateComponent } from '../layout/default-template/default-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [HomeComponent],
  imports: [CommonModule, TranslateModule, SharedModule, ReactiveFormsModule, FormsModule],
  exports: [HomeComponent],
})
export class HomeModule {}
