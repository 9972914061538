import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicLayout } from '../../models/dynamic-layout.model';
import { Content } from '../../models/content.model';
import { LayoutService } from '../layout.service';
import { UserService } from '../../user/user.service';
import { Subscription } from 'rxjs';
import { SettingsProvider } from '../../shared/settings.provider';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss'],
})
export class PrivacyPolicyPageComponent implements OnInit, OnDestroy, DynamicLayout {
  baseContent: Content;
  settingsSubscription: Subscription;

  body: SafeHtml;
  title: string;
  accepted: boolean;
  updating = false;

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private settingsProvider: SettingsProvider,
    private userService: UserService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = this.sanitizer.bypassSecurityTrustHtml(bodyField.value.value);
    this.title = titleField.value.value;
    this.settingsSubscription = this.settingsProvider.settingsChanged.subscribe(() => {
      this.accepted = this.settingsProvider.settings.data.user.acceptedPrivacyPolicy;
    });

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  ngOnDestroy(): void {
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }

  accept(): void {
    this.updating = true;

    this.userService
      .acceptPrivacyPolicy()
      .then(() => this.settingsProvider.refreshUserSettings())
      .then(() => this.router.navigate(['/']))
      .finally(() => (this.updating = false));
  }
}
