import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsRestService } from '../api/rest/settings-rest.service';
import { IsLoadingService } from '@service-work/is-loading';
import { Settings } from 'src/app/entities/settings';
import { MxLoggerService } from '@motivforce/mx-library-angular';
import { SupportRestService } from '../api/rest/support-rest.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { Router } from '@angular/router';
import { ConfirmationDetailsInput } from 'src/app/models/confirmation-details-input';

@Injectable({
  providedIn: 'root',
})
export class SupportStoreService {
  constructor(
    private supportRest: SupportRestService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogService,
    private mxLogger: MxLoggerService,
    private router: Router,
  ) {}

  submitHelpRequest(form: any, endpoint: any) {
    return this.supportRest.submitHelpRequest(form, endpoint);
  }

  getCurrentCourseList() {
    return this.supportRest.getCurrentCourseList();
  }

  getCurrentIncentiveList(): Promise<any> {
    return this.supportRest.getCurrentIncentiveList();
  }

  getCurrentProductsList(incentiveId: number): Promise<any> {
    return this.supportRest.getCurrentProductsList(incentiveId);
  }

  submitRedHatDetails(formDetails: FormData): void {
    this.isLoadingService.add(
      this.supportRest.submitRedHatDetails(formDetails).subscribe(
        () => {
          this.dialog.openModal(
            'Thank you for submitting your Red Hat details.',
            'Red Hat Details Submitted',
          );
        },
        () => {
          this.dialog.openModal(
            'There was an error submitting your Red Hat details.',
            'Red Hat Details Error',
          );
        },
      ),
    );
  }

  confirmCompetitiveDisplacementItem(form: ConfirmationDetailsInput): void {
    this.isLoadingService.add(
      this.supportRest.confirmCompetitiveDisplacementItem(form).subscribe(
        () => {
          this.router.navigate(['/pre-sales-confirmation']);
          this.dialog.openModal(
            'Thank you for confirming the competitive displacement details.',
            'Competitive Displacement Details Confirmed',
          );
        },
        () => {
          this.router.navigate(['/pre-sales-confirmation']);
          this.dialog.openModal(
            'There was an error confirming the competitive displacement details.',
            'Competitive Displacement Details Error',
          );
        },
      ),
    );
  }

  confirmRedHatItem(form: ConfirmationDetailsInput): void {
    this.isLoadingService.add(
      this.supportRest.confirmRedHatItem(form).subscribe(
        () => {
          this.router.navigate(['/pre-sales-confirmation']);
          this.dialog.openModal(
            'Thank you for confirming the Red Hat details.',
            'Red Hat Details Confirmed',
          );
        },
        () => {
          this.router.navigate(['/pre-sales-confirmation']);
          this.dialog.openModal(
            'There was an error confirming the Red Hat details.',
            'Red Hat Details Error',
          );
        },
      ),
    );
  }
}
