import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { skip, take } from 'rxjs/operators';
import { UserStoreService } from 'src/app/core/store/user-store.service';
import { AuthenticationService } from '../../authentication.service';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
})
export class LoginContainerComponent implements OnInit {
  userIsFlash: boolean = false;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private userStore: UserStoreService,
  ) {}

  async ngOnInit() {
    try {
      const user = await this.authService.currentUserInfo();
      console.log(user);
      if (user) {
        // already logged in so go to sample page
        console.log('LOGIN PAGE: Redirect Home');
        this.router.navigate(['/auth/login']);
      }
    } catch (e) {
      console.log(e);
    }

    this.userStore.flashDetails$.pipe(skip(1), take(1)).subscribe((flashDetails) => {
      this.userIsFlash = flashDetails.flashProgramAvailable;
    });
  }

  async login() {
    const l = await this.authService.ibmSignIn();
  }

  async loginPower() {
    const l = await this.authService.powerSignIn();
    if (l) {
      this.router.navigate(['/auth/my-profile']);
    }
  }

  async loginPowerHome() {
    const l = await this.authService.powerSignIn();
    if (l) {
      this.router.navigate(['/home']);
    }
  }
}
