import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { stat } from 'fs';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Content } from 'src/app/models/content.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { ApplicationFormOptions, ApplicationOptionItem } from '../models/application-form-options';
import { ApplicationStatus } from '../models/application-status';
import { IsLoadingService } from '@service-work/is-loading';
import { AccountStatementByYear, YearRequirementList } from 'src/app/account/points-summary-models';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { BankingRestService } from 'src/app/core/api/rest/banking-rest.service';

@Component({
  selector: 'app-mastercard-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit, OnDestroy {
  @Input()
  baseContent: Content;

  @Input()
  applicationStatus: EventEmitter<ApplicationStatus>;

  public loading: boolean;
  public updating = false;

  public isLoading$ = this.isLoadingService.isLoading$;
  applicationOptions: ApplicationFormOptions;
  earnedEnoughIncentivePoints: boolean = false;
  applicationStatusSub: Subscription;
  states: ApplicationOptionItem[];
  titles: ApplicationOptionItem[];

  applicationForm: any = {};
  mastercardApplicationForm = new FormGroup({
    title: new FormControl('', Validators.required),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    dateOfBirth: new FormControl('', Validators.required),
    addressLine1: new FormControl('', Validators.required),
    addressLine2: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl(''),
    postalCode: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    phoneCountry: new FormControl('', Validators.required),
    acceptTerms: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
  });

  notEligibleContent: string;
  body: string;
  constructor(
    private bankingRest: BankingRestService,
    private dialog: DialogNgbService,
    private router: Router,
    public settingsProvider: SettingsProvider,
    private isLoadingService: IsLoadingService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.states = [];
    this.titles = [];

    this.applicationStatusSub = this.applicationStatus.subscribe((status) => {
      if (status) {
        if (!status.isEligible) {
          this.dialog.openError(['You are not eligible to access the Mastercard']);
        }

        if (status.activeCardPresent) {
          this.router.navigate(['/transactions']);
        }

        if (status.applicationFormCompleted) {
          if (!status.identificationComplete) {
            this.router.navigate(['/identification']);
          } else if (!status.documentUploaded) {
            this.router.navigate(['/upload-documents']);
          } else if (!status.cardActivated) {
            this.router.navigate(['/activate']);
          }
        }
      }
    });

    this.mastercardApplicationForm.controls.email.patchValue(
      `${this.settingsProvider.settings.data.user.email}`,
    );
    this.mastercardApplicationForm.controls.firstName.patchValue(
      `${this.settingsProvider.settings.data.user.firstName}`,
    );
    this.mastercardApplicationForm.controls.lastName.patchValue(
      `${this.settingsProvider.settings.data.user.lastName}`,
    );

    this.bankingRest.getApplicationOptions().then((options) => {
      this.mastercardApplicationForm.controls.country.patchValue(options.countryName);
      this.states = options.states;
      this.titles = options.titles;
      this.mastercardApplicationForm.controls.phoneCountry.patchValue(options.countryCode);
      if (options.countryName == 'United States' || options.countryName === 'Canada') {
        this.mastercardApplicationForm.controls.state.setValidators(Validators.required);
      } else {
        this.mastercardApplicationForm.controls.state.patchValue('Other');
      }
    });
    this.loading = false;
  }

  onSubmit() {
    this.updating = true;

    if (this.mastercardApplicationForm.valid) {
      _.merge(this.applicationForm, this.mastercardApplicationForm.value);
      this.isLoadingService.add(
        this.bankingRest
          .submitApplication(this.applicationForm)
          .then((account) => {
            if (account) {
              this.router.navigate(['/user-identification']);
            } else {
              this.updating = false;
              this.dialog.openError(['An error has occurred, please try again later']);
            }
          })
          .catch((error) => {
            this.updating = false;
            this.dialog.openError(['An error has occurred, please try again later']);
          }),
      );
    } else {
      this.updating = false;
      let messages: string[] = [];
      Object.keys(this.mastercardApplicationForm.controls).forEach((key) => {
        if (!this.mastercardApplicationForm.get(key).valid) {
          let label = key.replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);
          // let firstLetter = key.substring(0, 1).toLocaleUpperCase();
          // let nameWithoutFirst = key.substring(1, key.length);
          // let name = firstLetter + nameWithoutFirst;
          messages.push(label + ' is required;');
        }
      });
      this.dialog.openValidation(messages, 'Please check the application');
    }
  }

  ngOnDestroy() {
    if (this.applicationStatusSub) {
      this.applicationStatusSub.unsubscribe();
    }
  }
}
