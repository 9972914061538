import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { Auth } from 'aws-amplify';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CoreTokenInterceptor implements HttpInterceptor {
  constructor(public authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let preSalesAssessment = request.url.search('presales/assessment-details/confirm');
    let preSalesMeeting = request.url.search('presales/meeting-details/confirm');
    let competitiveDisplace = request.url.search('competitive-displace-details/confirm');
    let redHat = request.url.search('red-hat/confirm');
    if (
      preSalesAssessment < 0 &&
      preSalesMeeting < 0 &&
      competitiveDisplace < 0 &&
      redHat < 0 &&
      (request.url.indexOf(environment.api.core.baseUrl) > -1 ||
        request.url.indexOf(environment.api.core.bankingUrl) > -1)
    ) {
      return from(Auth.currentSession()).pipe(
        switchMap((session) => {
          const token = session.getIdToken().getJwtToken();
          const headers = {
            Authorization: `Bearer ${token}`,
          };

          const impersonateUserId = this.authenticationService.getImpersonatedUserId();
          if (impersonateUserId) {
            headers['admin-impersonate-user-id'] = impersonateUserId;
          }

          const kyiPowerUserId = this.authenticationService.getKyiRedirectUserId();
          if (kyiPowerUserId) {
            headers['kyi-power-user-id'] = kyiPowerUserId;
          }

          request = request.clone({
            setHeaders: headers,
          });
          return next.handle(request);
        }),
      );
    }

    return next.handle(request);
  }
}
