<app-default-layout>
  <div class="container content">
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content">
        <p>
          <span *ngIf="availableBalance"><strong>Avaliable balance</strong></span>
          <span *ngIf="!availableBalance"><strong>Your account summary</strong></span>
        </p>
        <ng-container *ngIf="availableBalance">
          <div class="mt-2 balance">
            <span class="me-1 symbol"
              ><strong>{{ currencySymbol }}</strong></span
            ><strong>{{ availableBalance }}</strong>
          </div>
        </ng-container>
      </div>
      <div class="d-none d-lg-block col-lg-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12">
        <div [innerHtml]="topContent"></div>
        <app-sales-search [salesAccountSearchResult]="salesAccountSearchResult"></app-sales-search>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content">
        <p>&nbsp;</p>
      </div>
      <div class="d-none d-lg-block col-lg-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12">
        <div [innerHtml]="topContent"></div>
        <app-presales-search
          [presalesAccountSearchResult]="presalesAccountSearchResult"
        ></app-presales-search>
      </div>
    </div>
  </div>
</app-default-layout>
