import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { Settings } from 'src/app/entities/settings';
import { SupportService } from '../support.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { MxLoggerService } from '@motivforce/mx-library-angular';
import _ from 'lodash';
import { SupportStoreService } from 'src/app/core/store/support-store.service';

@Component({
  selector: 'sp-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  categories = [
    {
      label: 'Sales Claiming',
      id: 1,
    },
    {
      label: 'IBM Rewards Card',
      id: 2,
    },
    {
      label: 'Other',
      id: 3,
    },
  ];

  contactUsForm: FormGroup;
  settings: Settings;
  public requestForm = {};

  constructor(
    public fb: FormBuilder,
    public settingsProvider: SettingsProvider,
    private supportService: SupportStoreService,
    private dialogService: DialogService,
    private mxLogger: MxLoggerService,
  ) {
    this.settings = settingsProvider.settings.data;
  }

  ngOnInit() {
    this.initContactForm();
  }

  initContactForm() {
    return (this.contactUsForm = new FormGroup({
      name: new FormControl(`${this.settings.user.firstName} ${this.settings.user.lastName}`),
      email: new FormControl(`${this.settings.user.email}`, [Validators.required]),
      message: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required]),
      category: new FormControl(''),
    }));
  }

  onSubmit() {
    if (this.contactUsForm.valid) {
      const formdata = this.contactUsForm.getRawValue();
      _.merge(this.requestForm, this.contactUsForm.value);
      this.supportService.submitHelpRequest(this.requestForm, 'contactus').subscribe((data) => {
        this.dialogService.openModal('Your Contact Form has been submitted.');
        this.initContactForm();
      });
    } else {
      this.mxLogger.trace('formGroup:', this.contactUsForm);
      const controlkeys = Object.keys(this.contactUsForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.contactUsForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);

          if (controlkeys[i] === 'email') {
            errorFieldMessages.push(`A valid email address is required.`);
          } else {
            errorFieldMessages.push(`${label} is required.`);
          }
        }
      }

      this.dialogService.openModal(errorFieldMessages, 'Important');
    }
  }
}
