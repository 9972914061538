import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { SplashScreenService } from './shared/splash-screen.service';
import { SettingsProvider } from './shared/settings.provider';
import { ApiError } from '@motivforce/mx-library-angular';
import {
  ApiAwsService,
  ApiGqlService,
  ApiRestService,
  MxLoggerService,
  NotificationStoreService,
} from '@motivforce/mx-library-angular';
import { DialogService } from './shared/dialog/dialog.service';
import { SettingsStoreService } from './core/store/settings-store.service';
import { filter } from 'rxjs/operators';
import { Settings } from './entities/settings';
import * as localStorage from 'local-storage';
import { UserStoreService } from './core/store/user-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'power-rewards';

  constructor(
    private translate: TranslateService,
    private router: Router,
    private splashScreenService: SplashScreenService,
    private settingsProvider: SettingsProvider,
    private mxLogger: MxLoggerService,
    private dialog: DialogService,
    private settingsStore: SettingsStoreService,
    private apiRest: ApiRestService,
    private apiGql: ApiGqlService,
    private apiAws: ApiAwsService,
    private notificationStore: NotificationStoreService,
    private userStore: UserStoreService,
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language

    translate.setDefaultLang(settingsProvider?.settings?.language || 'en-US');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (
      settingsProvider &&
      settingsProvider.settings &&
      settingsProvider.settings.data &&
      settingsProvider.settings.data.language
    ) {
      translate.use(settingsProvider.settings.data.language);
    }
  }

  ngOnInit() {
    this.settingsStore.settings$.pipe(filter(Boolean)).subscribe((settings: Settings) => {
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      if (settings.language) {
        this.translate.use(settings.language);
      }

      const urlKey = localStorage.get<string>('power-rewards-url-key');
      const promotionId = localStorage.get<string>('power-rewards-promotion-id');

      this.notificationStore.getNotifications();

      this.userStore.getUserBlocks();
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.apiRest.error$.subscribe((apiError) => this.onApiError(apiError));
    this.apiGql.error$.subscribe((apiError) => this.onApiError(apiError));
    this.apiAws.error$.subscribe((apiError) => this.onApiError(apiError));
  }

  onApiError(apiError: ApiError): void {
    this.mxLogger.debug('AppComponent', 'onApiError() apiError=', apiError);

    if (apiError.name === 'authentication' || apiError.name === 'authorization') {
      // TODO align with MX Admin portal
      // this.authenticationStore.clearSession();
      // this.router.navigate(['/auth/login']);
    } else if (apiError.name === 'server') {
      // TODO fix BE errors before uncommenting
      // this.dialog.openModal(
      //   apiError.messages && apiError.messages.length > 0 ? apiError.messages : apiError.message,
      //   'A problem occurred',
      // );
    } else if (apiError.name === 'warning') {
      this.dialog.openModal(apiError.message, 'Important');
    } else if (apiError.name === 'validation') {
      this.dialog.openModal(apiError.messages, 'Important');
    } else {
      this.dialog.openModal(
        apiError.message ? apiError.message : apiError.messages.toString(),
        'Important',
      );
    }
  }
}
