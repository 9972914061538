import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Content, DialogNgbService } from '@motivforce/mx-library-angular';
import { filter, take } from 'rxjs/operators';
import { UserStoreService } from 'src/app/core/store/user-store.service';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-pay-tm-content-template',
  templateUrl: './pay-tm-content-template.component.html',
  styleUrls: ['./pay-tm-content-template.component.scss'],
})
export class PayTmContentTemplateComponent implements OnInit {
  @Input()
  baseContent: Content;

  body: string;
  bottomContent: string;
  paytmConfirmedStatus: boolean;
  confirmedContent: string;

  formGroup: FormGroup = this.formBuilder.group({
    mobileTelephone: ['', [Validators.required]],
  });

  constructor(
    private layoutService: LayoutService,
    private dialogService: DialogNgbService,
    private formBuilder: FormBuilder,
    private userStoreService: UserStoreService,
  ) {}

  ngOnInit(): void {
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bottomContentField = this.baseContent.fields.find((f) => f.name === 'Bottom Content');
    const confirmedContentField = this.baseContent.fields.find(
      (f) => f.name === 'Confirmed Content',
    );

    this.body = bodyField ? bodyField.value.value : '';
    this.bottomContent = bottomContentField ? bottomContentField.value.value : '';
    this.confirmedContent = confirmedContentField ? confirmedContentField.value.value : '';

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });

    this.userStoreService.payTmConfirmedStatus$
      .pipe(filter(Boolean), take(1))
      .subscribe((paytmConfirmedStatus: boolean) => {
        this.paytmConfirmedStatus = paytmConfirmedStatus;
      });

    this.userStoreService.getPaytmConfirmedStatus();
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const form = this.formGroup.getRawValue();
      this.userStoreService.updatePayTmDetails(form.mobileTelephone);
    } else {
      this.dialogService.openNotification(['Please enter an mobile phone number']);
    }
  }
}
