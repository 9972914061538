import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCoreParserFormatter } from '../shared/ngb-date-core-parser-formatter';
import { StatementPointsSummaryComponent } from './statement-points-summary/statement-points-summary.component';
import { PointsSearchComponent } from './points-search/points-search.component';
import { DownloadStatementComponent } from './download-statement/download-statement.component';
import { AccountStatementPageComponent } from './account-statement-page/account-statement-page.component';
import { DefaultTemplateComponent } from '../layout/default-template/default-template.component';
import { SharedModule } from '../shared/shared.module';
import { GenericListModule } from '@motivforce/mx-library-angular';
import { SalesAccountStatementComponent } from './sales-account-statement/sales-account-statement.component';
import { RouterModule } from '@angular/router';
import { PresalesAccountStatementComponent } from './presales-account-statement/presales-account-statement.component';

@NgModule({
  declarations: [
    StatementPointsSummaryComponent,
    PointsSearchComponent,
    SalesAccountStatementComponent,
    PresalesAccountStatementComponent,
    DownloadStatementComponent,
    AccountStatementPageComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    GenericListModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    RouterModule,
  ],
  providers: [NgbDateCoreParserFormatter, NgbPopover],
  exports: [
    StatementPointsSummaryComponent,
    PointsSearchComponent,
    SalesAccountStatementComponent,
    PresalesAccountStatementComponent,
    DownloadStatementComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccountModule {}
