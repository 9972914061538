import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { UserKyiSaleGroup } from 'src/app/core/model/user/user-kyi-sale-group';
import { SupportStoreService } from 'src/app/core/store/support-store.service';
import { Settings } from 'src/app/entities/settings';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { SupportService } from 'src/app/support/support.service';

@Component({
  selector: 'app-sale-claim-page',
  templateUrl: './sale-claim-page.component.html',
  styleUrls: ['./sale-claim-page.component.scss'],
})
export class SaleClaimPageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  sectionOneMainContent: string;
  sectionOneSideContent: string;
  sectionTwoMainContent: string;
  sectionTwoSideContent: string;
  bottomLeftBoxContent: string;
  bottomMiddleBoxContent: string;
  bottomRightBoxContent: string;
  charityDonationDialogContent: string;
  competitiveDisplacementContent: string;

  saleDetails: UserKyiSaleGroup;
  settings: Settings;
  submitting: boolean = false;

  hideSubmenu = false;

  competitiveDisplaceForm = this.formBuilder.group({
    bPartnerFullName: [null, Validators.required],
    bPartnerEmail: [null, Validators.required],
    companyName: [null, Validators.required],
    dealRegistrationNumber: [null, Validators.required],
    machineTypeModel: [null, Validators.required],
    serialNumber: [null, Validators.required],
    endCustomerName: [null, Validators.required],
  });

  machineTypes: any[] = [
    {
      name: '9105-22B',
    },
    {
      name: '9105-22A',
    },
    {
      name: '9105-41B',
    },
    {
      name: '9105-42A',
    },
    {
      name: '9786-22H',
    },
    {
      name: '9786-42H',
    },
    {
      name: '9043-MRX',
    },
    {
      name: '9080-HEX',
    },
  ];

  newCustomerForm = this.formBuilder.group({
    companyName: [null, Validators.required],
    country: [null, Validators.required],
  });

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private dialog: DialogNgbService,
    private formBuilder: FormBuilder,
    private supportService: SupportStoreService,
    private dialogService: DialogService,
    public settingsProvider: SettingsProvider,
  ) {
    this.settings = settingsProvider.settings.data;
  }

  ngOnInit(): void {
    this.competitiveDisplaceForm
      .get('bPartnerFullName')
      .setValue(`${this.settings.user.firstName} ${this.settings.user.lastName}`);
    this.competitiveDisplaceForm.get('bPartnerEmail').setValue(`${this.settings.user.email}`);
    this.competitiveDisplaceForm
      .get('companyName')
      .setValue(`${this.settings.user.companies.find((c) => c.isPrimary)?.name}`);
    const titleField = this.baseContent.fields.find((f) => f.name === 'Banner Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');

    const competitiveDisplacementContentField = this.baseContent.fields.find(
      (f) => f.name === 'Competitive Displacement Content',
    );
    const sectionOneMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section One Main Content',
    );
    const sectionOneSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section One Side Content',
    );
    const sectionTwoMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Main Content',
    );
    const sectionTwoSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Side Content',
    );
    const bottomLeftBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Left Box Content',
    );
    const bottomMiddleBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Middle Box Content',
    );
    const bottomRightBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Right Box Content',
    );
    const charityDonationDialogContentField = this.baseContent.fields.find(
      (f) => f.name === 'Charity Donation Popup',
    );

    this.sectionOneMainContent = sectionOneMainContentField.value.value;
    this.sectionOneSideContent = sectionOneSideContentField.value.value;
    this.sectionTwoMainContent = sectionTwoMainContentField.value.value;
    this.sectionTwoSideContent = sectionTwoSideContentField.value.value;
    this.bottomLeftBoxContent = bottomLeftBoxContent.value.value;
    this.bottomMiddleBoxContent = bottomMiddleBoxContent.value.value;
    this.bottomRightBoxContent = bottomRightBoxContent.value.value;
    this.charityDonationDialogContent = charityDonationDialogContentField.value.value;
    this.competitiveDisplacementContent = competitiveDisplacementContentField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });

    const region = this.settings.user?.region?.regionCode;
    if (region !== 'NA' && region !== 'LA') {
      this.hideSubmenu = true;
    }
  }

  viewDetails(sale: UserKyiSaleGroup): void {
    this.router.navigate(['/sale-details/' + sale.id]);
  }

  openMissingInvoice() {
    this.dialog.openFeatureDialog('missingInvoiceDialog', [], { size: 'lg' });
  }

  onSubmit() {
    this.submitting = true;
    if (this.competitiveDisplaceForm.valid) {
      this.supportService
        .submitHelpRequest(this.competitiveDisplaceForm.value, 'competitive-displace-details')
        .subscribe((data) => {
          this.dialogService.openModal(
            'Thank you for submitting your inquiry to check if your end customer is a Competitive Displace. We will confirm shortly. Your Power Team',
          );
          this.competitiveDisplaceForm.reset();
          this.competitiveDisplaceForm
            .get('bPartnerFullName')
            .setValue(`${this.settings.user.firstName} ${this.settings.user.lastName}`);
          this.competitiveDisplaceForm.get('bPartnerEmail').setValue(`${this.settings.user.email}`);
          this.competitiveDisplaceForm
            .get('companyName')
            .setValue(`${this.settings.user.companies.find((c) => c.isPrimary)?.name}`);
          this.submitting = false;
        });
    } else {
      const controlkeys = Object.keys(this.competitiveDisplaceForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.competitiveDisplaceForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);

          errorFieldMessages.push(`${label} is required.`);
        }
      }

      this.dialogService.openModal(errorFieldMessages, '');
      this.submitting = false;
    }
  }

  onSubmitNewCustomer() {
    if (this.newCustomerForm.valid) {
      this.supportService
        .submitHelpRequest(this.newCustomerForm.value, 'power/customer-check')
        .subscribe((data) => {
          this.dialogService.openModal(
            'Thank you for submitting your inquiry to check if your end customer is a new Power client.  We will confirm shortly.  Your Power Team.',
          );
          this.newCustomerForm.reset();
        });
    } else {
      const controlkeys = Object.keys(this.newCustomerForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.newCustomerForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);

          errorFieldMessages.push(`${label} is required.`);
        }
      }

      this.dialogService.openModal(errorFieldMessages, '');
    }
  }
}
