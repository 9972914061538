import { DatePipe, CurrencyPipe, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  BasePage,
  DialogNgbService,
  GenericListColumn,
  MxLoggerService,
} from '@motivforce/mx-library-angular';
import { GenericListState } from '@motivforce/mx-library-angular/lib/generic-list/model/generic-list-state';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { UserKyiSale } from '../../core/model/user/user-kyi-sale';
import { UserKyiSaleGroup } from '../../core/model/user/user-kyi-sale-group';
import { SalesClaimStoreService } from '../../core/store/sales-claim-store.service';

@Component({
  selector: 'app-sale-details',
  templateUrl: './sale-details.component.html',
  styleUrls: ['./sale-details.component.scss'],
})
export class SaleDetailsComponent implements OnInit, OnDestroy {
  @Input() saleId: number;
  @Output() saleDetailsClose: EventEmitter<void> = new EventEmitter();

  saleDetails: UserKyiSaleGroup;

  listPage: BasePage = {
    pageSize: 10,
    pageNumber: 1,
    orderBy: null,
  };

  resultsLength: number;
  dataProvider: UserKyiSale[];

  saleListColumnsProvider: GenericListColumn[] = [
    {
      columnNameDisplayKey: 'End Customer Name',
      sortable: false,
      fieldName: 'endCustomerName',
    },
    {
      columnNameDisplayKey: 'Invoice Number',
      sortable: false,
      fieldName: 'invoiceNumber',
      width: '170px',
    },
    {
      columnNameDisplayKey: 'Invoice Date',
      sortable: false,
      fieldName: 'saleDate',
      width: '150px',
      labelFunction: (item: UserKyiSale) =>
        this.datePipe.transform(
          item.saleDate,
          this.settingsStore.settings?.user?.program?.dateFormat
            ? this.settingsStore.settings?.user?.program?.dateFormat
            : 'dd-MM-yyyy',
        ),
    },
    {
      columnNameDisplayKey: 'Part Number',
      sortable: false,
      fieldName: 'productCode',
      width: '135px',
    },
    {
      columnNameDisplayKey: 'Serial Number',
      sortable: false,
      fieldName: 'serialNumber',
      width: '145px',
    },
    {
      columnNameDisplayKey: 'Quantity',
      sortable: false,
      fieldName: 'quantity',
      width: '90px',
    },
    {
      columnNameDisplayKey: 'Revenue',
      sortable: false,
      fieldName: 'totalValue',
      width: '120px',
      labelFunction: (item: UserKyiSale) => this.currencyPipe.transform(item.totalValue),
    },
  ];

  onDestroy$: Subject<any> = new Subject();

  constructor(
    private salesClaimStore: SalesClaimStoreService,
    private mxLogger: MxLoggerService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private dialog: DialogNgbService,
    public settingsStore: SettingsStoreService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.salesClaimStore.currentSaleGroup$
      .pipe(skip(1), takeUntil(this.onDestroy$))
      .subscribe((sale) => {
        this.saleDetails = sale;

        this.updatePagingLabels();
      });

    this.salesClaimStore.currentSaleSearchResult$
      .pipe(skip(1), takeUntil(this.onDestroy$))
      .subscribe((searchResult) => {
        this.resultsLength = searchResult.statistics.totalRecords;
        this.dataProvider = searchResult.results;

        this.updatePagingLabels();
      });

    this.salesClaimStore.getUserSaleGroup(this.saleId);
    this.salesClaimStore.searchUserSales(this.saleId, this.listPage);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  updatePagingLabels(): void {
    const pageSizeBeforeElement = this.document.body.querySelector(
      '.page-options-size div:nth-child(1)',
    );
    if (pageSizeBeforeElement) pageSizeBeforeElement.innerHTML = 'Items per page:';

    const pageSizeAfterElement = this.document.body.querySelector(
      '.page-options-size div:nth-child(3)',
    );

    if (pageSizeAfterElement)
      pageSizeAfterElement.innerHTML = this.resultsLength
        ? (this.listPage.pageNumber - 1) * this.listPage.pageSize +
          1 +
          '-' +
          this.listPage.pageNumber * this.listPage.pageSize +
          ' of ' +
          this.resultsLength +
          ' items.'
        : '';
  }

  isClaimed(): boolean {
    return (
      this.saleDetails &&
      this.saleDetails.claimedDetails &&
      (this.saleDetails.claimedDetails.salesClaimedBy !== null ||
        this.saleDetails.claimedDetails.companyClaimedBy !== null ||
        this.saleDetails.claimedDetails.technicalClaimedBy !== null)
    );
  }

  onListStateChanged(event: GenericListState): void {
    this.listPage.orderBy =
      event.sortColumn && event.sortColumn !== ''
        ? {
            fields: [
              { sortBy: event.sortColumn, sortDirection: event.sortDirection as 'asc' | 'desc' },
            ],
          }
        : null;

    this.listPage.pageSize = event.pageSize;
    this.listPage.pageNumber = event.page;

    this.salesClaimStore.searchUserSales(this.saleId, this.listPage);
  }
}
