<div class="main-wrapper" *ngIf="saleDetails">
  <div class="row" *ngIf="isClaimed()">
    <div class="col-md-12">
      <div class="mb-30 ml-10 mt-15">
        <h5>{{ 'Sale details' | translate }}</h5>
      </div>

      <div class="d-none d-md-block">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngIf="
                saleDetails?.claimedDetails?.salesClaimedBy &&
                (!saleDetails.claimedDetails.technicalClaimedBy ||
                  saleDetails.claimedDetails.technicalClaimedBy !==
                    saleDetails.claimedDetails.salesClaimedBy)
              "
            >
              <tr class="claimed-details-row">
                <td>
                  <span class="claimed-details-row-label">{{ 'Claimed by' | translate }}:</span
                  ><span>{{ saleDetails.claimedDetails.salesClaimedBy }}</span>
                </td>
                <td></td>
                <td>
                  <span class="claimed-details-row-label">
                    {{ 'Claimed by' | translate }}
                    {{ 'Date' | translate }}:</span
                  ><span>{{
                    saleDetails.claimedDetails.salesClaimedDate
                      | date
                        : (settingsStore.settings?.user?.program?.dateFormat
                            ? settingsStore.settings?.user?.program?.dateFormat
                            : 'dd-MM-yyyy')
                  }}</span>
                </td>
                <td>
                  <span class="claimed-details-row-label">
                    {{ 'Claimed by' | translate }}
                    {{ 'Pending Until' | translate }}:</span
                  ><span>{{
                    saleDetails.claimedDetails.salesClaimedPendingUntil
                      | date
                        : (settingsStore.settings?.user?.program?.dateFormat
                            ? settingsStore.settings?.user?.program?.dateFormat
                            : 'dd-MM-yyyy')
                  }}</span>
                </td>
                <td></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="d-block d-sm-none">
        <div
          class="claimed-details-row"
          *ngIf="
            saleDetails?.claimedDetails?.salesClaimedBy &&
            (!saleDetails.claimedDetails.technicalClaimedBy ||
              saleDetails.claimedDetails.technicalClaimedBy !==
                saleDetails.claimedDetails.salesClaimedBy)
          "
        >
          <div class="col-sm-12 pt-10 pb-10">
            <p>
              <span class="claimed-details-row-label"
                ><strong>{{ 'Claimed by' | translate }}: </strong> </span
              ><span>{{ saleDetails.claimedDetails.salesClaimedBy }}</span>
            </p>
            <p>
              <span class="claimed-details-row-label"
                ><strong>
                  {{ 'Claimed by' | translate }}
                  {{ 'Date' | translate }}:
                </strong></span
              ><span>{{
                saleDetails.claimedDetails.salesClaimedDate
                  | date
                    : (settingsStore.settings?.user?.program?.dateFormat
                        ? settingsStore.settings?.user?.program?.dateFormat
                        : 'dd-MM-yyyy')
              }}</span>
            </p>
            <p>
              <span class="claimed-details-row-label">
                <strong
                  >{{ 'Claimed by' | translate }} {{ 'Pending Until' | translate }}:
                </strong></span
              ><span>{{
                saleDetails.claimedDetails.salesClaimedPendingUntil
                  | date
                    : (settingsStore.settings?.user?.program?.dateFormat
                        ? settingsStore.settings?.user?.program?.dateFormat
                        : 'dd-MM-yyyy')
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="d-none d-md-block">
        <lib-generic-list
          [dataProvider]="dataProvider"
          [totalCount]="resultsLength"
          [columnsProvider]="saleListColumnsProvider"
          [serverPaging]="true"
          [tableStriped]="false"
          [actionsColumnLeft]="false"
          (stateChanged)="onListStateChanged($event)"
        >
        </lib-generic-list>
      </div>

      <div class="d-block d-sm-none pt-20">
        <div *ngFor="let row of dataProvider">
          <div *ngFor="let column of saleListColumnsProvider">
            <div class="col-sm-12">
              <p>
                <strong>{{ column.columnNameDisplayKey }}: </strong>
                <span
                  *ngIf="
                    column.labelFunction &&
                    column.fieldName !== 'detail' &&
                    column.fieldName !== 'action' &&
                    column.fieldName !== 'totalValue'
                  "
                  >{{
                    row[column.fieldName]
                      | date
                        : (settingsStore.settings?.user?.program?.dateFormat
                            ? settingsStore.settings?.user?.program?.dateFormat
                            : 'dd-MM-yyyy')
                  }}</span
                >
                <span *ngIf="column.labelFunction && column.fieldName === 'totalValue'">{{
                  row[column.fieldName] | currency
                }}</span>
                <span
                  *ngIf="
                    !column.labelFunction &&
                    column.fieldName !== 'detail' &&
                    column.fieldName !== 'action'
                  "
                  >{{ row[column.fieldName] }}</span
                >
              </p>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="saleDetails">
  <div class="bottom-content">
    <div class="row">
      <div class="col-md-12 d-flex flex-row justify-content-end">
        <div class="d-flex total-wrapper">
          <div class="total-label">
            <strong>{{ 'Total Revenue' | translate }}</strong>
          </div>
          <div>{{ saleDetails.transactionTotalAmount | currency }}</div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="saleDetails.totalReward !== undefined">
      <!-- <div class="col-md-2">
        <strong>{{ 'Reward' | translate }}</strong>
      </div>
      <div class="col-md-2">{{ saleDetails.totalReward | currency }}</div> -->

      <div class="col-md-12 d-flex flex-row justify-content-end">
        <div class="d-flex total-wrapper">
          <div class="total-label">
            <strong>{{ 'Reward' | translate }}</strong>
          </div>
          <div>{{ saleDetails.totalReward | currency }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
