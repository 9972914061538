import { Injectable } from '@angular/core';
import { ApiRestService, BaseSiteRestService } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { ConfirmationDetailsInput } from 'src/app/models/confirmation-details-input';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupportRestService extends BaseSiteRestService {
  constructor(protected apiRestService: ApiRestService) {
    super(environment.api.core.baseUrl, environment.api.core.path.member, 'kyi/support');
  }

  submitHelpRequest(form: any, endpoint: any) {
    const endpointUrl = `${this.memberBaseUrl}/${endpoint}`;
    return this.apiRestService.post<any>(endpointUrl, form);
  }

  getCurrentCourseList() {
    const endpointUrl = `${this.memberBaseUrl}/courses`;
    return this.apiRestService.get<any>(endpointUrl);
  }

  getCurrentIncentiveList(): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/incentives`;
    return this.apiRestService.get<any>(endpointUrl).toPromise();
  }

  getCurrentProductsList(incentiveId: number): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/products/` + incentiveId;
    return this.apiRestService.get<any>(endpointUrl).toPromise();
  }

  confirmCompetitiveDisplacementItem(form: ConfirmationDetailsInput): Observable<any> {
    let endpointUrl = `${this.memberBaseUrl}/competitive-displace-details/confirm/${form.itemId}/?action=${form.action}&reason=${form.reason}`;
    if (form.clientIdentifier) {
      endpointUrl += `&clientIdentifier=${form.clientIdentifier}`;
    }
    if (form.hashIdentifier) {
      endpointUrl += `&hashIdentifier=${form.hashIdentifier}`;
    }
    return this.apiRestService.post<any>(endpointUrl, null);
  }

  submitRedHatDetails(formDetails: FormData): Observable<any> {
    return this.apiRestService.post<any>(`${this.memberBaseUrl}/red-hat`, formDetails);
  }

  confirmRedHatItem(form: ConfirmationDetailsInput): Observable<any> {
    let endpointUrl = `${this.memberBaseUrl}/red-hat/confirm/${form.itemId}/?action=${form.action}&reason=${form.reason}`;
    if (form.clientIdentifier) {
      endpointUrl += `&clientIdentifier=${form.clientIdentifier}`;
    }
    if (form.hashIdentifier) {
      endpointUrl += `&hashIdentifier=${form.hashIdentifier}`;
    }
    return this.apiRestService.post<any>(endpointUrl, null);
  }
}
