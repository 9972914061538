import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogNgbService, ReferenceData } from '@motivforce/mx-library-angular';
import { UserKyiSaleGroup } from 'src/app/core/model/user/user-kyi-sale-group';
import { SalesClaimStoreService } from 'src/app/core/store/sales-claim-store.service';
import { SupportStoreService } from 'src/app/core/store/support-store.service';
import { Settings } from 'src/app/entities/settings';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { Subject, Observable } from 'rxjs';
import { filter, takeUntil, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CompanyStoreService } from 'src/app/core/store/company-store.service';

@Component({
  selector: 'app-red-hat-page',
  templateUrl: './red-hat-page.component.html',
  styleUrls: ['./red-hat-page.component.scss'],
})
export class RedHatPageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  body: string;
  bottomLeftBoxContent: string;
  bottomMiddleBoxContent: string;
  bottomRightBoxContent: string;
  charityDonationDialogContent: string;
  sectionTwoMainContent: string;
  sectionTwoSideContent: string;

  redHatForm = this.formBuilder.group({
    userId: [null],
    machineTypeModel: [null, Validators.required],
    serialNumber: [null, Validators.required],
    orderDate: [null, Validators.required],
    dealRegistrationNumber: [null, Validators.required],
    endCustomerName: [null, Validators.required],
    transactionDateRedHat: [null, Validators.required],
    revenue: [null, Validators.required],
    invoiceCopy: [null, Validators.required],
  });

  invoiceFile: File;
  submitting: boolean = false;
  isPrc: boolean = false;

  userTypeahead = (text$: Observable<string>) =>
    text$.pipe(
      filter((searchText) => searchText.length > 2),
      distinctUntilChanged(),
      switchMap((searchText) => this.companyStore.searchUsersInCompany(searchText)),
    );

  settings: Settings;

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private supportService: SupportStoreService,
    public settingsProvider: SettingsProvider,
    private dialog: DialogNgbService,
    private companyStore: CompanyStoreService,
  ) {
    this.settings = this.settingsProvider.settings.data;
  }

  ngOnInit(): void {
    const region = this.settings.user?.region?.regionCode;
    if (region !== 'NA' && region !== 'LA') {
      this.router.navigate(['/']);
      return;
    }

    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');
    const sectionTwoMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Main Content',
    );
    const sectionTwoSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Side Content',
    );
    const bottomLeftBoxContentField = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Left Box Content',
    );
    const bottomMiddleBoxContentField = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Middle Box Content',
    );
    const bottomRightBoxContentField = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Right Box Content',
    );
    const charityDonationDialogContentField = this.baseContent.fields.find(
      (f) => f.name === 'Charity Donation Popup',
    );

    this.body = bodyField.value.value;
    this.bottomLeftBoxContent = bottomLeftBoxContentField.value.value;
    this.bottomMiddleBoxContent = bottomMiddleBoxContentField.value.value;
    this.bottomRightBoxContent = bottomRightBoxContentField.value.value;
    this.charityDonationDialogContent = charityDonationDialogContentField.value.value;
    this.sectionTwoMainContent = sectionTwoMainContentField.value.value;
    this.sectionTwoSideContent = sectionTwoSideContentField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });

    this.isPrc = this.settings.user?.userRole.name === 'kyi.power.firm.prc.access';
  }

  onSubmit() {
    this.submitting = true;
    if (this.redHatForm.valid) {
      const values = new FormData();
      values.append('userId', this.redHatForm.value.userId?.id);
      values.append('machineTypeModel', this.redHatForm.value.machineTypeModel);
      values.append('serialNumber', this.redHatForm.value.serialNumber);
      values.append('orderDate', this.redHatForm.value.orderDate);
      values.append('dealRegistrationNumber', this.redHatForm.value.dealRegistrationNumber);
      values.append('endCustomerName', this.redHatForm.value.endCustomerName);
      values.append('transactionDate', this.redHatForm.value.transactionDateRedHat);
      values.append('revenue', this.redHatForm.value.revenue);
      values.append('purchasedThrough', this.redHatForm.value.invoiceCopy);
      values.append('file', this.invoiceFile);

      this.supportService.submitRedHatDetails(values);
      this.submitting = false;
    } else {
      const controlkeys = Object.keys(this.redHatForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.redHatForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);

          errorFieldMessages.push(`${label} is required.`);
        }
      }

      this.dialogService.openModal(errorFieldMessages, '');
      this.submitting = false;
    }
  }

  handleInvoiceUpload(event) {
    this.invoiceFile = event.target.files[0] as File;
  }

  openMissingInvoice() {
    this.dialog.openFeatureDialog('missingInvoiceDialog', [], { size: 'lg' });
  }

  viewDetails(sale: UserKyiSaleGroup): void {
    this.router.navigate(['/sale-details/' + sale.id]);
  }

  formatUsername(user: ReferenceData) {
    return user.name;
  }
}
