import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '../../account/account.service';
import {
  PointsSummary,
  YearPointsSummary,
  YearRequirementList,
  AccountStatement,
  AccountStatementByYear,
  AccountStatements,
} from '../../account/points-summary-models';
import { BehaviorSubject } from 'rxjs';
import { Content } from '../../models/content.model';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { stat } from 'fs';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { filter, skip, take } from 'rxjs/operators';
import { DateSearch } from 'src/app/models/search/date-search';
import { PagedResult } from 'src/app/models/search/search-result';
import { LayoutService } from 'src/app/layout/layout.service';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { BankingStoreService } from 'src/app/core/store/banking-store.service';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-account-statement-page',
  templateUrl: './account-statement-page.component.html',
  styleUrls: ['./account-statement-page.component.scss'],
})
export class AccountStatementPageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  @Output()
  salesAccountSearchResult: EventEmitter<PagedResult<AccountStatement>> = new EventEmitter();
  @Output()
  presalesAccountSearchResult: EventEmitter<PagedResult<AccountStatement>> = new EventEmitter();

  viewPointsSummary: PointsSummary;

  topContent: string;
  notAllCompleted: boolean;

  statement: boolean;
  searchContext: DateSearch = {
    searchText: null,
    startDate: '2022-01-01',
    endDate: new Date().toISOString(),
    pageSize: 30,
    pageNumber: 1,
  };

  availableBalance: number;
  currencySymbol: string;

  // searchResult: PagedResult<AccountStatement> = {
  //   results: [],
  //   statistics: {
  //     currentPage: 1,
  //     totalPages: 1,
  //     totalRecords: 0,
  //   },
  // };

  constructor(
    private layoutService: LayoutService,
    private accountService: AccountService,
    public settingsStore: SettingsStoreService,
    private bankingStore: BankingStoreService,
  ) {}

  ngOnInit() {
    this.statement = true;

    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const topContentField = this.baseContent.fields.find((f) => f.name === 'Top Content');

    this.accountService.getAccountStatement().then((data) => {});

    this.accountService.getSalesPointsSearchResults(this.searchContext).then((result) => {
      if (result != null) {
        this.salesAccountSearchResult.emit(result);
      }
    });

    this.accountService.getPreSalesPointsSearchResults(this.searchContext).then((result) => {
      if (result != null) {
        this.presalesAccountSearchResult.emit(result);
      }
    });

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
    this.topContent =
      topContentField && topContentField.value ? topContentField.value.value : undefined;

    this.bankingStore.activeBalance$.pipe(skip(1), take(1)).subscribe((data: any) => {
      this.availableBalance = data.availableBalance;
      this.currencySymbol = getCurrencySymbol(data.currency, 'narrow');
    });

    this.bankingStore.getActiveBalance();
  }
}
