<app-default-layout>
  <div class="container content">
    <div class="row">
      <div *ngIf="!paytmConfirmedStatus" class="col-12" [innerHtml]="body"></div>
      <div *ngIf="paytmConfirmedStatus" class="col-12" [innerHtml]="confirmedContent"></div>
    </div>

    <div class="p-3 pb-4 mb-3 form-wrapper">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-12 col-sm-6 col-xl-4">
            <div class="form-group">
              <label>Mobile phone number*</label>
              <input class="form-control" type="text" formControlName="mobileTelephone" />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xl-4">
            <div class="h-100 pb-2 d-flex align-items-end">
              <button type="submit" class="btn btn-primary">
                Submit<span class="arrow">→</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row">
      <div *ngIf="!paytmConfirmedStatus" class="col-12" [innerHtml]="bottomContent"></div>
    </div>
  </div>
</app-default-layout>
