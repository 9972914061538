<app-default-layout
  [title]="'<h1>Manage my profile</h1>'"
  [bannerUrl]="'../../../assets/images/Landing-banner.jpg'"
  [showBackToLanding]="true"
  [showLogo]="false"
  [mediumBanner]="true"
>
  <div class="main-page-container eligibility-container">
    <div class="d-none d-lg-block container content">
      <div class="row">
        <div class="col-lg-2 col-md-12">
          <p>
            <strong
              >You must complete all of the eligibility criteria before you can participate fully in
              the IBM Offerings.
            </strong>
          </p>
          <p>
            If you need assistance please contact
            <a href="mailto:power@kyirewards.com">power@kyirewards.com</a>
          </p>
        </div>
        <div class="d-none d-lg-block col-lg-1">
          <p>&nbsp;</p>
        </div>
        <div class="d-none d-lg-block col-lg-9 col-md-12">
          <p>
            <strong>Eligibilty progress:</strong>
          </p>
          <div class="row text-center new-class-not-used">
            <div class="pd-0" style="max-width: 18px">
              <span *ngIf="profile"><img src="../../../assets/images/complete-icon.svg" /></span>
              <span *ngIf="!profile"><img src="../../../assets/images/error-icon.svg" /></span>
            </div>
            <div style="width: 13%" class="pd-0"><hr /></div>
            <div class="pd-0" *ngIf="taxRequired" style="max-width: 18px">
              <span *ngIf="taxRequired && taxCompleted"
                ><img src="../../../assets/images/complete-icon.svg"
              /></span>
              <span *ngIf="taxRequired && !taxCompleted"
                ><img src="../../../assets/images/error-icon.svg"
              /></span>
            </div>
            <div style="width: 15%" class="pd-0" *ngIf="taxRequired"><hr /></div>

            <div class="pd-0" style="max-width: 18px">
              <span *ngIf="termsAccepted"
                ><img src="../../../assets/images/complete-icon.svg"
              /></span>
              <span *ngIf="!termsAccepted"
                ><img src="../../../assets/images/error-icon.svg"
              /></span>
            </div>
            <div style="width: 14%" class="pd-0"><hr /></div>

            <div class="pd-0" style="max-width: 18px">
              <span *ngIf="privacy"><img src="../../../assets/images/complete-icon.svg" /></span>
              <span *ngIf="!privacy"><img src="../../../assets/images/error-icon.svg" /></span>
            </div>
            <div style="width: 14%" class="pd-0"><hr /></div>

            <div class="text-center pd-0" style="max-width: 18px">
              <span *ngIf="communications"
                ><img src="../../../assets/images/complete-icon.svg"
              /></span>
              <span *ngIf="!communications"
                ><img src="../../../assets/images/error-icon.svg"
              /></span>
            </div>

            <div style="width: 14%" class="pd-0"><hr /></div>

            <div class="pd-0" style="max-width: 18px">
              <span *ngIf="integrityTraining"
                ><img src="../../../assets/images/complete-icon.svg"
              /></span>
              <span *ngIf="!integrityTraining"
                ><img src="../../../assets/images/error-icon.svg"
              /></span>
            </div>
          </div>
          <div class="row text-center">
            <div style="padding-left: 0; text-align: left; width: 10%; margin-left: -30px">
              <p style="padding-top: 20px">Your profile</p>
            </div>
            <div *ngIf="taxRequired" style="width: 16%">
              <p style="padding-top: 20px">
                Tax<br />
                requirements
              </p>
            </div>
            <div style="width: 16%">
              <p style="padding-top: 20px">
                Terms and<br />
                conditions
              </p>
            </div>
            <div style="padding-right: 0; width: 16%">
              <p style="padding-top: 20px">Privacy policy</p>
            </div>
            <div style="width: 16%">
              <p style="padding-top: 20px">Communication preferences</p>
            </div>
            <div style="text-align: right; width: 10%">
              <p style="padding-top: 20px">Integrity training</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-page-container profile-container">
    <div class="container content">
      <div class="row">
        <div class="d-none d-lg-block col-lg-3">
          <p>&nbsp;</p>
        </div>
        <div class="col-lg-9 col-md-12 mobile-padding">
          <form [formGroup]="profileForm" (ngSubmit)="submitRole()">
            <div class="col-md-12">
              <h3>Your profile</h3>
            </div>
            <div class="row pt-25">
              <div class="col-lg-4 col-md-12">
                <div class="col-sm-12">
                  <label>{{ 'Salutation' | translate }}</label>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="form-check-label" style="margin-right: 1em"
                      ><input type="radio" value="mr" formControlName="title" /> Mr</label
                    >
                    <label class="form-check-label" style="margin-right: 1em"
                      ><input type="radio" value="ms" formControlName="title" /> Ms</label
                    >
                    <label class="form-check-label" style="margin-right: 1em"
                      ><input type="radio" value="dr" formControlName="title" /> Dr</label
                    >
                    <label class="form-check-label" style="margin-right: 1em"
                      ><input type="radio" value="prof" formControlName="title" /> Prof</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12">
                <div class="col-sm-12">
                  <label>{{ 'First name' | translate }}</label>
                </div>
                <div class="col-sm-12">
                  <input type="text" formControlName="firstName" class="form-control" />
                </div>
              </div>
              <div class="col-lg-4 col-md-12">
                <div class="col-sm-12">
                  <label>{{ 'Last name' | translate }}</label>
                </div>
                <div class="col-sm-12">
                  <input type="text" formControlName="lastName" class="form-control" />
                </div>
              </div>
            </div>
            <div class="row pt-25">
              <div class="col-lg-4 col-md-12">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>
                      {{ 'Company' | translate }}
                    </label>
                    <input formControlName="companyName" type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>
                      {{ 'Location' | translate }}
                    </label>
                    <input formControlName="location" type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>
                      {{ 'Email' | translate }}
                    </label>
                    <input formControlName="email" type="text" class="form-control" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-25">
              <div class="col-lg-4 col-md-12">
                <div formGroupName="roles">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>
                        {{ 'Role' | translate }}
                      </label>
                      <select
                        formControlName="role"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && roleFormControl?.errors }"
                      >
                        <option>{{ 'Select role' | translate }}</option>
                        <option value="sales">{{ 'Sales' | translate }}</option>
                        <option value="technical sales">
                          {{ 'Technical Sales' | translate }}
                        </option>
                      </select>
                      <div *ngIf="submitted && roleFormControl?.errors" class="invalid-feedback">
                        {{ 'Role is required' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-25">
              <div class="col-sm-12" *ngIf="!profile">
                <button type="submit" class="btn btn-primary" style="line-height: 30px">
                  {{ 'Save' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
              <div class="col-lg-8 col-md-12" *ngIf="profile">
                <p>
                  If you wish to amend your profile please contact us at
                  <a href="mailto:power@kyirewards.com">power@kyirewards.com</a>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="main-page-container tax-container" *ngIf="taxRequirements">
    <div class="container content">
      <div class="row">
        <div class="col-md-3">
          <p [innerHtml]="taxHeading"></p>
        </div>
        <div class="col-md-9">
          <form [formGroup]="taxForm" (ngSubmit)="onSubmitTax()">
            <div class="row">
              <div class="col-sm-12" [innerHtml]="taxContent"></div>
            </div>

            <div *ngIf="taxRequirements?.primaryTaxIdentifier">
              <div class="form-group">
                <div>
                  <label [innerHtml]="primaryTaxIdentifierLabel"></label
                  ><fa-icon
                    class="ml-10"
                    [ngStyle]="{ cursor: 'pointer' }"
                    [icon]="['fas', 'info-circle']"
                    [transform]="'grow-2'"
                    (click)="showPrimaryTaxIdInfo()"
                    *ngIf="primaryTaxIdentifierInformation"
                  ></fa-icon>
                </div>
                <input
                  type="text"
                  class="form-control"
                  formControlName="primaryTaxIdentifier"
                  [ngClass]="{ 'is-invalid': submitted && primaryTaxIdentifierFormControl?.errors }"
                />
                <div
                  *ngIf="submitted && primaryTaxIdentifierFormControl?.errors"
                  class="invalid-feedback"
                >
                  {{ 'Required' | translate }}
                </div>
              </div>
            </div>
            <div *ngIf="taxRequirements?.dateOfBirth">
              <div class="form-group">
                <label [innerHtml]="dateOfBirthLabel"></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="dateOfBirth"
                  [ngClass]="{ 'is-invalid': submitted && dateOfBirthFormControl?.errors }"
                />
                <div *ngIf="submitted && dateOfBirthFormControl?.errors" class="invalid-feedback">
                  {{ 'Required' | translate }}
                </div>
              </div>
            </div>
            <div class="row pt-25" *ngIf="taxRequirements?.address">
              <div class="col-sm-12">
                <div class="form-group">
                  <h2 [innerHtml]="addressLabel"></h2>
                </div>
              </div>
            </div>
            <div *ngIf="taxRequirements?.address">
              <div class="form-group">
                <label>{{ 'Address Line 1' | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="address1"
                  [ngClass]="{ 'is-invalid': submitted && addressFormControl?.errors }"
                />
                <div *ngIf="submitted && addressFormControl?.errors" class="invalid-feedback">
                  {{ 'Address Line 1 is required' | translate }}
                </div>
              </div>
              <div class="form-group">
                <label>{{ 'Address Line 2' | translate }}</label>
                <input type="text" class="form-control" formControlName="address2" />
              </div>
              <div class="form-group">
                <label>{{ 'City' | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="city"
                  [ngClass]="{ 'is-invalid': submitted && cityFormControl?.errors }"
                />
                <div *ngIf="submitted && cityFormControl?.errors" class="invalid-feedback">
                  {{ 'City is required' | translate }}
                </div>
              </div>
              <div class="form-group">
                <label>{{ 'State' | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="state"
                  [ngClass]="{ 'is-invalid': submitted && stateFormControl?.errors }"
                />
                <div *ngIf="submitted && stateFormControl?.errors" class="invalid-feedback">
                  {{ 'State is required' | translate }}
                </div>
              </div>
              <div class="form-group">
                <label>{{ 'Zip Code' | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="postCode"
                  [ngClass]="{ 'is-invalid': submitted && postCodeFormControl?.errors }"
                />
                <div *ngIf="submitted && postCodeFormControl?.errors" class="invalid-feedback">
                  {{ 'Zip Code is required' | translate }}
                </div>
              </div>
            </div>
            <div class="row pt-25">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-primary" style="line-height: 30px">
                  {{ 'Save' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="main-page-container terms-container">
    <div class="container content">
      <div class="row">
        <div class="col-md-3">
          <p>
            <strong>Terms and conditions</strong>
          </p>
        </div>
        <div class="col-md-9">
          <form [formGroup]="termsForm" (ngSubmit)="onSubmitTerms()">
            <div class="form-group row">
              <div class="col-sm-12">
                <p>
                  I accept the
                  <span style="color: blue" (click)="showTerms()">Terms and Conditions</span>.
                </p>
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" value="true" formControlName="acceptTerms" />
                  {{ 'Yes' | translate }}
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" value="false" formControlName="acceptTerms" />
                  {{ 'No' | translate }}
                </label>
              </div>
            </div>
            <div class="row pt-25">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-primary" style="line-height: 30px">
                  {{ 'Save' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="main-page-container privacy-container">
    <div class="container content">
      <div class="row">
        <div class="col-md-3">
          <p>
            <strong>Privacy policy</strong>
          </p>
        </div>
        <div class="col-md-9">
          <form [formGroup]="privacyForm" (ngSubmit)="onSubmitPrivacy()">
            <div class="form-group row">
              <div class="col-sm-12">
                <p>
                  I accept the
                  <span style="color: blue" (click)="showPrivacy()">Privacy Policy</span>

                  terms.
                </p>
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" value="true" formControlName="acceptPrivacy" />
                  {{ 'Yes' | translate }}
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" value="false" formControlName="acceptPrivacy" />
                  {{ 'No' | translate }}
                </label>
              </div>
            </div>
            <div class="row pt-25">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-primary" style="line-height: 30px">
                  {{ 'Save' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="main-page-container communications-container">
    <div class="container content">
      <div class="row pt-25">
        <div class="col-md-3">
          <p><strong>My communications preferences</strong></p>
        </div>
        <div class="col-md-9">
          <form [formGroup]="communicationsForm" (ngSubmit)="onSubmitCommunications()">
            <div class="form-group row">
              <div class="col-sm-12">
                <p>
                  Would you like to receive details about new promotions, education and rewards? IBM
                  sends emails about your account, enabling you to get the most from your
                  membership.
                </p>
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" value="true" formControlName="sendEmail" />
                  {{ 'Yes! Send me marketing emails' | translate }}
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" value="false" formControlName="sendEmail" />
                  {{ 'No, please do not email me' | translate }}
                </label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <p>
                  Please note that if you do opt out of IBM’s marketing emails, you will still
                  receive important emails regarding your account, such as account statements,
                  critical deadlines, new education, program updates, invoices available to claim
                  and personal rewards redemptions.
                </p>
              </div>
            </div>
            <div class="row pt-25">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-primary" style="line-height: 30px">
                  {{ 'Save' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="main-page-container video-container" *ngIf="regionCode === 'NA'">
    <div class="container content">
      <div class="row">
        <div class="col-md-3">
          <p></p>
        </div>
        <div class="col-md-9">
          <p>
            You will receive a $50 USD for successfully registering into the IBM Power Rewards
            Incentive, however if you have benefited previously under the IBM Flash Reward
            incentive, you will not be able to earn again on the Power getting started benefit. You
            will receive a $50 load to your existing IBM Rewards Card, or you can apply for your IBM
            Rewards Card <a [routerLink]="'/ibm-rewards-card'">here.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="main-page-container integrity-container">
    <div class="container content">
      <div class="row">
        <div class="col-md-3">
          <p>
            <strong>Integrity training</strong>
          </p>
        </div>
        <div class="col-md-9">
          <p *ngIf="!integrityTraining">
            You must complete the
            <a
              href="https://www.ibm.com/partnerworld/program/compliance/integrity-education"
              target="_blank"
              >IBM Integrity Training</a
            >. To register for the training please contact us direct at
            <a href="mailto:power@kyirewards.com">power@kyirewards.com</a>
          </p>
          <p *ngIf="integrityTraining">
            You have successfully completed the
            <a
              href="https://www.ibm.com/partnerworld/program/compliance/integrity-education"
              target="_blank"
              >IBM Integrity Training</a
            >.
          </p>
          <br />
          <p>
            Hopefully, you have managed to work your way through the required conditions to
            participate fully in the IBM Power Rewards program.
          </p>
          <p>
            If you need any support please do contact us at
            <a href="mailto:power@kyirewards.com">power@kyirewards.com</a> and we will be delighted
            to support you.
          </p>
          <button [routerLink]="'/home'" class="d-none d-sm-block btn btn-primary">
            Go to IBM Power Rewards
          </button>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
