<div class="form-box">
  <div class="row">
    <h4>Pre-sales account statement</h4>
  </div>
  <form [formGroup]="filtersForm" (ngSubmit)="onSubmit()" class="form-points-filter">
    <div class="row">
      <div class="row">
        <div class="col-md-4 col-sm-6 filter-container">
          <div class="form-group">
            <label>From</label>
            <div class="input-group">
              <input
                #dp1
                class="form-control"
                [placeholder]="
                  settingsStore.settings?.user?.program?.dateFormat
                    ? settingsStore.settings?.user?.program?.dateFormat
                    : 'dd-mm-yyyy'
                "
                name="dp1"
                [(ngModel)]="startDate"
                [ngModelOptions]="{ standalone: true }"
                ngbDatepicker
                #d="ngbDatepicker"
                placement="top"
                [positionTarget]="dp1"
                (click)="d.toggle()"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 filter-container">
          <div class="form-group">
            <label>To</label>
            <div class="input-group">
              <input
                #dp2
                class="form-control"
                [placeholder]="
                  settingsStore.settings?.user?.program?.dateFormat
                    ? settingsStore.settings?.user?.program?.dateFormat
                    : 'dd-mm-yyyy'
                "
                name="dp2"
                [(ngModel)]="endDate"
                [ngModelOptions]="{ standalone: true }"
                ngbDatepicker
                #dd="ngbDatepicker"
                placement="top"
                [positionTarget]="dp2"
                (click)="dd.toggle()"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="h-100 d-flex align-items-end">
            <button class="w-100 btn btn-primary search-button btn-card" type="submit">
              {{ 'Search' | translate }}
              <img src="../../../assets/images/Search.png" style="width: 14px; height: auto" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-sm-12 pd-0">
      <div class="row" *ngIf="showDownloadButtons">
        <div class="col-md-3">
          <button type="button" class="btn btn-primary" (click)="downloadCsv()">
            {{ 'Download CSV' | translate }}
          </button>
        </div>
      </div>
      <div class="d-none d-lg-block row">
        <div class="col-md-12">
          <p>&nbsp;</p>
        </div>
      </div>

      <table class="d-none d-sm-block table table-style table-responsive-sm-12">
        <thead>
          <tr>
            <th>{{ 'Pre-sales activity' | translate }}</th>
            <th>{{ 'End customer' | translate }}</th>
            <th>{{ 'Activity date' | translate }}</th>
            <th>{{ 'Status' | translate }}</th>
            <th>{{ 'Credit' | translate }}</th>
            <th>{{ 'Load date' | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="searchResult.results.length > 0">
          <tr *ngFor="let transaction of searchResult.results">
            <td>{{ transaction.invoiceNumber }}</td>
            <td>{{ transaction.endCustomer }}</td>
            <td *ngIf="transaction.invoiceDate">
              {{
                transaction.invoiceDate
                  | date
                    : (settingsStore.settings?.user?.program?.dateFormat
                        ? settingsStore.settings?.user?.program?.dateFormat
                        : 'dd-MM-yyyy')
              }}
            </td>
            <td *ngIf="!transaction.invoiceDate"></td>
            <td>{{ transaction.status }}</td>
            <td>${{ transaction.credit }}</td>
            <td style="min-width: 100px" *ngIf="transaction.loadDate">
              {{
                transaction.loadDate
                  | date
                    : (settingsStore.settings?.user?.program?.dateFormat
                        ? settingsStore.settings?.user?.program?.dateFormat
                        : 'dd-MM-yyyy')
              }}
            </td>
            <td *ngIf="!transaction.loadDate"></td>
          </tr>
        </tbody>
        <tbody *ngIf="searchResult.results.length === 0">
          <tr>
            <td colspan="9">No transactions found.</td>
          </tr>
        </tbody>
      </table>

      <div class="d-block d-sm-none" *ngIf="searchResult.results.length > 0">
        <div *ngFor="let transaction of searchResult.results">
          <div class="row">
            <div class="col-sm-12">
              <p>
                <strong>{{ 'Pre-sales activity' | translate }}: </strong>
                {{ transaction.invoiceNumber }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>
                <strong>{{ 'End customer' | translate }}: </strong>
                {{ transaction.endCustomer }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>
                <strong>{{ 'Activity date' | translate }}: </strong>
                <span *ngIf="transaction.invoiceDate">
                  {{
                    transaction.invoiceDate
                      | date
                        : (settingsStore.settings?.user?.program?.dateFormat
                            ? settingsStore.settings?.user?.program?.dateFormat
                            : 'dd-MM-yyyy')
                  }}
                </span>
                <span *ngIf="!transaction.invoiceDate">&nbsp;</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>
                <strong>{{ 'Status' | translate }}: </strong>
                {{ transaction.status }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>
                <strong>{{ 'Credit' | translate }}: </strong>
                ${{ transaction.credit }}
              </p>
            </div>
          </div>
          <div class="row" *ngIf="transaction.loadDate">
            <div class="col-sm-12">
              <p>
                <strong>{{ 'Load date' | translate }}: </strong>

                {{
                  transaction.loadDate
                    | date
                      : (settingsStore.settings?.user?.program?.dateFormat
                          ? settingsStore.settings?.user?.program?.dateFormat
                          : 'dd-MM-yyyy')
                }}
              </p>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div class="row" *ngIf="searchResult.results.length > 0">
        <div class="col-sm-12">
          <ngb-pagination
            (pageChange)="pageChanged($event)"
            [collectionSize]="searchResult.statistics.totalRecords"
            [pageSize]="searchContext.pageSize"
            [(page)]="searchResult.statistics.currentPage"
            [boundaryLinks]="true"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
