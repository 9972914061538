import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AbstractDialogComponent,
  DialogNgbService,
  MxLoggerService,
} from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UserKyiSaleAction } from '../../core/model/user/user-kyi-sale-action';
import { SalesClaimStoreService } from '../../core/store/sales-claim-store.service';

@Component({
  selector: 'app-charity-donation-dialog',
  templateUrl: './charity-donation-dialog.component.html',
  styleUrls: ['./charity-donation-dialog.component.scss'],
})
export class CharityDonationDialogComponent
  extends AbstractDialogComponent
  implements OnInit, OnDestroy
{
  saleAction: UserKyiSaleAction;
  charityDonationDialogContent;

  formGroup: FormGroup = this.formBuilder.group({
    amount: [null, [Validators.required, Validators.pattern('^[0-9]*'), Validators.min(0)]],
  });

  onDestroy$: Subject<any> = new Subject();

  constructor(
    private mxLogger: MxLoggerService,
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private salesClaimStore: SalesClaimStoreService,
    private dialog: DialogNgbService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs.length > 0) {
      this.saleAction = this.inputs[0];
      this.charityDonationDialogContent = this.sanitizer.bypassSecurityTrustHtml(this.inputs[1]);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  get donatedPoints(): number {
    const amount = this.formGroup.controls.amount.value;

    return amount && !isNaN(amount) ? Math.round(amount / this.saleAction.pointCostValue) : 0;
  }

  donate(): void {
    if (this.donatedPoints > 0) {
      const saleAction = { ...this.saleAction, ...{ points: this.donatedPoints } };

      this.salesClaimStore
        .donate(saleAction)
        .then(() => this.activeModal.close(saleAction))
        .then(() => {
          if (this.donatedPoints > 0) {
            this.dialog.openNotification([
              'Thank you for your donation, please check your email for confirmation of your charitable contribution. Your IBM Power Rewards Team',
            ]);
          }
        });
    } else {
      this.activeModal.close(null);
      this.dialog.openNotification([
        'You have confirmed you do not wish to donate to charity. Your IBM Power Rewards Team',
      ]);
    }
  }

  close(): void {
    this.activeModal.close(null);
  }
}
