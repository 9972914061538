import { Injectable } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';
import { PointsSummary } from 'src/app/account/points-summary-models';
import { User } from 'src/app/models/user';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { UserRestService } from '../api/rest/user-rest.service';
import * as fileSaver from 'file-saver';
import { DateSearch } from 'src/app/models/search/date-search';
import { UserBlock } from 'src/app/models/user-block';
import { UserProgramDetails } from '../model/user/user-program-details';
import { DialogNgbService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private readonly _flashDetails = new BehaviorSubject<UserProgramDetails | null>(null);
  readonly flashDetails$ = this._flashDetails.asObservable();

  private readonly _accountSummary = new BehaviorSubject<PointsSummary>(null);
  readonly accountSummary$ = this._accountSummary.asObservable();

  private readonly _usersInCompany = new BehaviorSubject<User[]>(null);
  readonly usersInCompany$ = this._usersInCompany.asObservable();

  private readonly _systemsDetails = new BehaviorSubject<UserProgramDetails>(null);
  readonly systemsDetails$ = this._systemsDetails.asObservable();

  private readonly _userBlocks = new BehaviorSubject<UserBlock[] | null>(null);
  readonly userBlocks$ = this._userBlocks.asObservable();

  private readonly _payTmConfirmedStatus = new BehaviorSubject<boolean | null>(null);
  readonly payTmConfirmedStatus$ = this._payTmConfirmedStatus.asObservable();

  constructor(
    private userRest: UserRestService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogNgbService,
  ) {}

  get flashDetails(): UserProgramDetails {
    return this._flashDetails.getValue();
  }

  get accountSummary(): PointsSummary {
    return this._accountSummary.getValue();
  }

  get usersInCompany(): User[] {
    return this._usersInCompany.getValue();
  }

  get systemDetails(): UserProgramDetails {
    return this._systemsDetails.getValue();
  }

  get userBlocks(): UserBlock[] | null {
    return this._userBlocks.getValue();
  }

  get payTmConfirmedStatus(): boolean | null {
    return this._payTmConfirmedStatus.getValue();
  }

  getFlashDetails(): void {
    if (this.flashDetails) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getFlashDetails().subscribe((flashDetails: UserProgramDetails) => {
        this._flashDetails.next(flashDetails);
      }),
    );
  }

  getAccountSummary(): void {
    this.isLoadingService.add(
      this.userRest.getAccountSummary().subscribe((accountSummary: PointsSummary) => {
        this._accountSummary.next(accountSummary);
      }),
    );
  }

  getSystemsDetails(): void {
    if (this.systemDetails) {
      return;
    }

    this.userRest.getSystemsDetails().subscribe((systemsDetails) => {
      this._systemsDetails.next(systemsDetails);
    });
  }

  downloadReport(endpoint: string, searchContext: DateSearch): void {
    this.isLoadingService.add(
      this.userRest.downloadReport(endpoint, searchContext).subscribe((result) => {
        const blob: any = new Blob([result.content], { type: result.contentType });
        fileSaver.saveAs(blob, result.fileName);
      }),
    );
  }

  downloadStatement(fileType: string, startDate: string, endDate: string): void {
    this.isLoadingService.add(
      this.userRest.downloadStatement(fileType, startDate, endDate).subscribe((result) => {
        const blob: any = new Blob([result.content], { type: result.contentType });
        fileSaver.saveAs(blob, result.fileName);
      }),
    );
  }

  setPrimaryCompany(companyId: number): void {
    this.isLoadingService.add(
      this.userRest.setPrimaryCompany(companyId).subscribe(() => {
        window.location.reload();
      }),
    );
  }

  getUserBlocks(): void {
    if (this.userBlocks) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getUserBlocks().subscribe((blocks: UserBlock[]) => {
        this._userBlocks.next(blocks);
      }),
    );
  }

  getPaytmConfirmedStatus(): void {
    if (this.payTmConfirmedStatus === null) {
      this.isLoadingService.add(
        this.userRest.getPaytmConfirmedStatus().subscribe((status) => {
          this._payTmConfirmedStatus.next(status);
        }),
      );
    }
  }

  updatePayTmDetails(mobileTelephone: string): void {
    this.isLoadingService.add(
      this.userRest.updatePayTmDetails(mobileTelephone).subscribe(() => {
        this.dialog.openNotification([
          'Thank you for submitting, please check your email for the validation link',
        ]);
      }),
    );
  }

  confirmPayTm(form: any): Promise<any> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.confirmPayTm(form).subscribe(
          () => {
            resolve(true);
          },
          () => resolve(false),
        ),
      );
    });
  }
}
