import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCoreParserFormatter } from '../../shared/ngb-date-core-parser-formatter';
import { AccountService } from '../account.service';
import { PagedResult } from '../../models/search/search-result';
import { DateSearch } from '../../models/search/date-search';
import { LearnResult } from './models';
import { LayoutService } from 'src/app/layout/layout.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { UserStoreService } from 'src/app/core/store/user-store.service';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { CustomNgbDateParserFormatter } from 'src/app/shared/custom-ngb-date-parser-formatter';

function pad(digits: number, length: number): string {
  let str = '' + digits;
  while (str.length < length) {
    str = '0' + str;
  }

  return str;
}

@Component({
  selector: 'app-points-search',
  templateUrl: './points-search.component.html',
  styleUrls: ['./points-search.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }],
})
export class PointsSearchComponent implements OnInit {
  pointsTypes = [
    {
      label: 'Learn',
      title: 'Learn Points',
      type: 'learn',
    },
    {
      label: 'Promo',
      title: 'Promo Points',
      type: 'promo',
    },
    {
      label: 'Tier Credit',
      title: 'Tier Credits',
      type: 'tier',
    },
    {
      label: 'Expired',
      title: 'Expired Points',
      type: 'expired',
    },
  ];

  selectedType = {
    label: '',
    title: '',
    type: 'none',
  };

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  showDownloadButtons: boolean = false;

  searchContext: DateSearch = {
    searchText: null,
    startDate: null,
    endDate: new Date().toLocaleString(),
    pageSize: 30,
    pageNumber: 1,
  };

  searchResult: PagedResult<LearnResult> = {
    results: [],
    statistics: {
      currentPage: 1,
      totalPages: 1,
      totalRecords: 0,
    },
  };

  filtersForm = this.formBuilder.group({
    pointsType: ['learn'],
    searchText: [null],
  });

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private ngbDateParserFormatter: NgbDateCoreParserFormatter,
    private dialogService: DialogService,
    public settingsStore: SettingsStoreService,
    private userStore: UserStoreService,
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.selectedType = this.pointsTypes.find((i) => i.type === this.filtersForm.value.pointsType);
    let startDateString: string = null;
    let endDateString: string = null;

    if (this.startDate != null) {
      startDateString = this.ngbDateParserFormatter.formatForApi(this.startDate);
    }

    if (this.endDate != null) {
      endDateString = this.ngbDateParserFormatter.formatForApi(this.endDate);
    }

    if (
      (this.startDate == null && this.endDate == null) ||
      (this.startDate != null && this.endDate == null) ||
      (this.startDate == null && this.endDate != null)
    ) {
      this.dialogService.openModal('Please enter a start and end date to search by.');
      console.log('Both dates must be used and not one!');
    } else {
      this.searchContext.pageNumber = 1;
      this.searchContext.startDate = startDateString;
      this.searchContext.endDate = endDateString;
      this.executeSearch();
    }
  }

  pageChanged(page: number) {
    console.log('Page changed: ' + page);
    this.searchContext.pageNumber = page;
    this.executeSearch();
  }
  downloadExcel() {
    this.selectedType = this.pointsTypes.find((i) => i.type === this.filtersForm.value.pointsType);
    let startDateString: string = null;
    let endDateString: string = null;

    if (this.startDate != null) {
      startDateString = this.ngbDateParserFormatter.formatForApi(this.startDate);
    }

    if (this.endDate != null) {
      endDateString = this.ngbDateParserFormatter.formatForApi(this.endDate);
    }

    if (
      (this.startDate == null && this.endDate == null) ||
      (this.startDate != null && this.endDate == null) ||
      (this.startDate == null && this.endDate != null)
    ) {
      this.dialogService.openModal('Please enter a start and end date to search by.');
      console.log('Both dates must be used and not one!');
    } else {
      this.searchContext.pageNumber = 1;
      this.searchContext.startDate = startDateString;
      this.searchContext.endDate = endDateString;
      this.searchContext.fileType = 'excel';
      this.executeDownload();
    }
  }

  downloadCsv() {
    this.selectedType = this.pointsTypes.find((i) => i.type === this.filtersForm.value.pointsType);
    let startDateString: string = null;
    let endDateString: string = null;

    if (this.startDate != null) {
      startDateString = this.ngbDateParserFormatter.formatForApi(this.startDate);
    }

    if (this.endDate != null) {
      endDateString = this.ngbDateParserFormatter.formatForApi(this.endDate);
    }

    if (
      (this.startDate == null && this.endDate == null) ||
      (this.startDate != null && this.endDate == null) ||
      (this.startDate == null && this.endDate != null)
    ) {
      this.dialogService.openModal('Please enter a start and end date to search by.');
      console.log('Both dates must be used and not one!');
    } else {
      this.searchContext.pageNumber = 1;
      this.searchContext.startDate = startDateString;
      this.searchContext.endDate = endDateString;
      this.searchContext.fileType = 'csv';
      this.executeDownload();
    }
  }

  executeDownload() {
    if (this.selectedType.type !== 'none' && this.selectedType.type !== null) {
      this.userStore.downloadReport(this.selectedType.type, this.searchContext);
    }
  }

  executeSearch() {
    if (this.selectedType.type !== 'none' && this.selectedType.type !== null) {
      this.searchContext.searchText = this.filtersForm.controls.searchText.value;

      //   this.accountService
      //     .getPointsSearchResults(this.searchContext)
      //     .then((result) => {
      //       if (result != null) {
      //         this.searchResult.results = result.results;
      //         this.searchResult.statistics.currentPage = result.statistics.currentPage;
      //         this.searchResult.statistics.totalRecords = result.statistics.totalRecords;
      //         this.searchResult.statistics.totalPages = result.statistics.totalPages;
      //         this.showDownloadButtons = true;
      //       }
      //     })
      //     .catch((error) => {
      //       console.error(`Points search error: `, error);
      //     });
    }
  }
}
