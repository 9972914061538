<div class="modal-body m-3 d-flex flex-column justify-content-center">
  <p><strong>IBM Power Rewards Sales Incentive&nbsp;</strong></p>
  <p>
    <strong
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc">(Available in all countries with KYI sales incentives)</span></span
        ></span
      ></strong
    >
  </p>
  <p>
    <span style="font-weight: 400"
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >This IBM PartnerWorld &ndash; Know Your IBM Supplement (&ldquo;KYI Supplement&rdquo;)
            as described in the IBM PartnerWorld &ndash; Know Your IBM Attachment (&ldquo;KYI
            Attachment&rdquo;) conveys the details of an eligible KYI offering. The terms of this
            Supplement prevail over the terms of the KYI Attachment and the terms of the IBM
            PartnerWorld Agreement &ndash; International Basic General Terms
            (&ldquo;PWA&rdquo;)</span
          ></span
        ></span
      ></span
    >
  </p>
  <p>
    <strong>Start Date: July 12</strong><strong>th</strong><strong>, 2022&nbsp;<br /></strong
    ><strong>End Date: December 31</strong><strong>st</strong><strong>, 2022&nbsp;</strong>
  </p>
  <p><strong>The Offer&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >IBM Channel Ecosystem operates on a Preferred Distributor model. To qualify for IBM
            Hardware or Software incentives, each Business Partner is required to select their
            Preferred Distributor. Only purchases made from your Preferred Distributor will be
            counted towards incentives described in this offering.</span
          ></span
        ></span
      ></span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Qualifying Sellers of IBM client facing Business Partner - Resellers who acquire from
            an IBM Authorized Distributor and resell to a qualifying end user eligible IBM Power or
            sell eligible IBM Power as a Service part numbers may qualify to earn reward funds that
            will be placed on to a reloadable IBM Rewards Card equal to the value shown on the
            eligible IBM Power product list shown below.</span
          ></span
        ></span
      ></span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >A qualifying transaction is a single transaction sale to a single end user customer.
            Multiple orders for the same end user may be placed for a single transaction resulting
            in multiple invoices. These are all viewed as a single transaction provided all
            transactions are invoiced to the same end user and there are no more than 10 business
            days between the first and last invoice. Maximum earnings per transaction $20,000 USD
            ($5,000 USD for Hong Kong &amp; Taiwan).</span
          ></span
        ></span
      ></span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Sellers and Technical Sellers can earn for Pre-Sales activities to NEW IBM Power
            Clients for Client Meeting as well as performing Client Assessments. Maximum per client
            is $500 USD. Pre-sales activities are valid for new Power 10 client meetings only.</span
          ></span
        ></span
      ></span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >For the purposes of this incentive, the full-time Seller or Technical Seller of the IBM
            Business Partner claiming a reward under this incentive represents and acts on behalf of
            their IBM Business Partner (Reseller) company. Reward funds earned under this incentive
            will count towards the annual maximum as shown on the table below.</span
          ></span
        ></span
      ></span
    >
  </p>
  <p>
    <strong>Note </strong
    ><span style="font-weight: 400"
      >that Firm level payment option is not available under the IBM Power Rewards incentive.</span
    >
  </p>
  <table border="1" cellpadding="10px">
    <thead>
      <tr>
        <th><strong>Country&nbsp;</strong></th>
        <th><strong>Individual Earnings Limit under this incentive (USD)</strong></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <span class="VIiyi" lang="en"
            ><span
              class="JLqJ4b"
              data-language-for-alternatives="en"
              data-language-to-translate-into="en"
              data-phrase-index="0"
              data-number-of-phrases="1"
              ><span class="Q4iAWc">Hong Kong, Taiwan</span></span
            ></span
          >
        </td>
        <td>$5,000 per qualifying transaction</td>
      </tr>
      <tr>
        <td>
          <span class="VIiyi" lang="en"
            ><span
              class="JLqJ4b"
              data-language-for-alternatives="en"
              data-language-to-translate-into="en"
              data-phrase-index="0"
              data-number-of-phrases="1"
              ><span class="Q4iAWc"
                >Aland Islands, Albania, Andorra, Austria, Belgium, Bosnia &amp; Herzegovina,
                British Indian Ocean Territory, Bulgaria, Comoros, Croatia, Cyprus, Czech Republic,
                Denmark, Estonia, Faroe Islands, Falkland Islands, Finland, France, French Guyana,
                French Polynesia , French Southern Territories, Germany, Gibraltar, Greece,
                Greenland, Guadeloupe, Guernsey, Hungary, Iceland, Ireland, Isle of Man, Israel,
                Italy, Jersey, Latvia, Liechtenstein, Lithuania, Luxembourg, Macedonia, Malta,
                Martinique, Mayotte, Monaco , Montenegro, the Netherlands, New Caledonia, Norway,
                Pitcairn Island, Poland, Portugal, Reunion, Romania, Saint Helena, San Marino,
                Serbia, Slovakia, Slovenia, South Georgia &amp; South Sandwich Island, Spain Pierre
                and Miquelon, Sweden, Switzerland, the United Kingdom, Vanuatu, Vatican City, Wallis
                &amp; Futuna, UAE, Qatar, Kuwait, Saudi Arabia, Morocco, South Africa, Turkey,
                Tunisia, Argentina, Brazil, Chile, Colombia, Ecuador, Mexico , Peru, Uruguay,
                Australia, New Zealand, Indonesia, Malaysia, Philippines, Singapore, Thailand,
                India, Sri Lanka, Korea, Vietnam, American Samoa, Guam, Puerto Rico, United States,
                U.S. Virgin Islands, Canada</span
              ></span
            ></span
          >
        </td>
        <td>$20,000 per qualifying&nbsp;&nbsp;transaction</td>
      </tr>
      <tr>
        <th><strong>Maximum Individual Earnings Cap per calendar year</strong></th>
        <th>&nbsp;</th>
      </tr>
      <tr>
        <td>Hong Kong, Taiwan</td>
        <td>$10,000</td>
      </tr>
      <tr>
        <td>Turkey, Tunisia</td>
        <td>$20,000</td>
      </tr>
      <tr>
        <td>Morocco, South Africa</td>
        <td>$45,000</td>
      </tr>
      <tr>
        <td>
          Argentina, Brazil, Chile, Colombia, Ecuador, Mexico, Peru, Uruguay, Indonesia, Malaysia,
          Philippines, Thailand, India, Sri Lanka, Korea, Vietnam
        </td>
        <td>$50,000</td>
      </tr>
      <tr>
        <td>UAE, Qatar, Kuwait, Saudi Arabia</td>
        <td>$60,000</td>
      </tr>
      <tr>
        <td>
          Aland Islands, Albania, Andorra, Austria, Belgium, Bosnia &amp; Herzegovina, British
          Indian Ocean Territory, Bulgaria, Comoros, Croatia, Cyprus, Czech Republic, Denmark,
          Estonia, Faroe Islands, Falkland Islands, Finland, France, French Guyana, French Polynesia
          , French Southern Territories, Germany, Gibraltar, Greece, Greenland, Guadeloupe,
          Guernsey, Hungary, Iceland, Ireland, Isle of Man, Israel, Italy, Jersey, Latvia,
          Liechtenstein, Lithuania, Luxembourg, Macedonia, Malta, Martinique, Mayotte, Monaco ,
          Montenegro, the Netherlands, New Caledonia, Norway, Pitcairn Island, Poland, Portugal,
          Reunion, Romania, Saint Helena, San Marino, Serbia, Slovakia, Slovenia, South Georgia
          &amp; South Sandwich Island, Spain Pierre and Miquelon, Sweden, Switzerland, United
          Kingdom, Vanuatu, Vatican City, Wallis &amp; Futuna, Australia, New Zealand, Singapore,
          American Samoa, Guam, Puerto Rico, United States, U.S. Virgin Islands, Canada
        </td>
        <td>$100,000 per calendar year</td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p><strong>Country Availability&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Aland Islands, Albania, Andorra, Austria, Belgium, Bosnia &amp; Herzegovina, British
            Indian Ocean Territory, Bulgaria, Comoros, Croatia, Cyprus, Czech Republic, Denmark,
            Estonia, Faeroe Islands, Falkland Islands, Finland, France, French Guyana, French
            Polynesia, French Southern Territories, Germany, Gibraltar, Greece, Greenland,
            Guadeloupe, Guernsey, Hungary, Iceland, Ireland, Isle of Man, Israel, Italy, Jersey,
            Latvia, Liechtenstein, Lithuania, Luxembourg, Macedonia, Malta, Martinique, Mayotte,
            Monaco, Montenegro, the Netherlands, New Caledonia, Norway, Pitcairn Island, Poland,
            Portugal, Reunion, Romania, Saint Helena, San Marino, Serbia, Slovakia, Slovenia, South
            Georgia &amp; South Sandwich Island, Spain, St. Pierre and Miquelon, Sweden,
            Switzerland, the United Kingdom, Vanuatu, Vatican City, Wallis &amp; Futuna, UAE, Qatar,
            Kuwait, Saudi Arabia, Morocco, South Africa, Turkey, Tunisia, Argentina, Brazil, Chile,
            Colombia, Ecuador, Mexico, Peru, Uruguay, Australia, New Zealand, Indonesia, Malaysia,
            Philippines, Singapore, Thailand, India, Sri Lanka, Korea, Vietnam, American Samoa,
            Guam, Puerto Rico, United States, U.S. Virgin Islands, Canada, Hong Kong, Taiwan</span
          ></span
        ></span
      ><br
    /></span>
  </p>
  <p><strong>IBM Power Reward Incentive Structure&nbsp;</strong></p>
  <p><strong>Getting started incentive (Available in North America only)&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >To get you started individual registrants will earn $50 USD that will be applied to their IBM
      Rewards Card when they complete the following:&nbsp;</span
    >
  </p>
  <p>
    <span style="font-weight: 400">&nbsp; - </span
    ><span style="font-weight: 400"
      >Ensure they have met all compliance requirements including&nbsp;&nbsp;</span
    >
  </p>
  <ul>
    <li>
      <span style="font-weight: 400"
        ><span class="VIiyi" lang="en"
          ><span
            class="JLqJ4b"
            data-language-for-alternatives="en"
            data-language-to-translate-into="en"
            data-phrase-index="0"
            data-number-of-phrases="1"
            ><span class="Q4iAWc">accepting the terms and conditions </span></span
          ></span
        ></span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        ><span class="VIiyi" lang="en"
          ><span
            class="JLqJ4b"
            data-language-for-alternatives="en"
            data-language-to-translate-into="en"
            data-phrase-index="0"
            data-number-of-phrases="1"
            ><span class="Q4iAWc">completing their profile information </span></span
          ></span
        ></span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        ><span class="VIiyi" lang="en"
          ><span
            class="JLqJ4b"
            data-language-for-alternatives="en"
            data-language-to-translate-into="en"
            data-phrase-index="0"
            data-number-of-phrases="1"
            ><span class="Q4iAWc">completion of the IBM Integrity Training </span></span
          ></span
        ></span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        ><span class="VIiyi" lang="en"
          ><span
            class="JLqJ4b"
            data-language-for-alternatives="en"
            data-language-to-translate-into="en"
            data-phrase-index="0"
            data-number-of-phrases="1"
            ><span class="Q4iAWc">applying for the IBM rewards Card</span></span
          ></span
        ></span
      >
    </li>
  </ul>
  <p>
    <strong
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >NOTE: if you already received the $50 USD benefit under the IBM Flash Reward incentive,
            you will not be able to earn again on the Power Getting started benefit.
          </span></span
        ></span
      ></strong
    >
  </p>
  <p><strong>Pre-Sales Activities&nbsp;&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      ><span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Available for individuals in a Seller and a Technical Seller role. Pre-sales activities
            are valid for new Power 10 client meetings only. Must have a valid deal registration.
            IBM representative must be in attendance to confirm pre-sales activities. End client
            cannot be considered a Government Owned Entity (&ldquo;GOE&rdquo;)</span
          ></span
        ></span
      ><br
    /></span>
  </p>
  <table border="1" cellpadding="10px">
    <thead>
      <tr>
        <th><strong>Activity</strong></th>
        <th><strong>Reward Value</strong></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <span class="VIiyi" lang="en"
            ><span
              class="JLqJ4b"
              data-language-for-alternatives="en"
              data-language-to-translate-into="en"
              data-phrase-index="0"
              data-number-of-phrases="1"
              ><span class="Q4iAWc"
                ><strong>Client Meeting</strong> (with IBM Representative attending) Deal
                Registration required
              </span></span
            ></span
          >
        </td>
        <td>$250 USD</td>
      </tr>
      <tr>
        <td>
          <span class="VIiyi" lang="en"
            ><span
              class="JLqJ4b"
              data-language-for-alternatives="en"
              data-language-to-translate-into="en"
              data-phrase-index="0"
              data-number-of-phrases="1"
              ><span class="Q4iAWc"
                ><strong>Assessment</strong> Workshop or Onsite/Virtual POC, Trial, Benchmark) Deal
                Registration required</span
              ></span
            ></span
          >
        </td>
        <td>$250 USD</td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p><strong>Seller Activities&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >Available for individuals in a Seller and a Technical Seller role. Sales benefits are valid
      for sales to new or competitive displace clients only. End client cannot be considered a GOE.
      Power Rewards are available for new purchases only, Miscellaneous Equipment Specification
      (&ldquo;MESs&rdquo;) are not eligible.<br
    /></span>
  </p>
  <table border="1" cellpadding="10px">
    <thead>
      <tr>
        <th><strong>Power Product Description</strong></th>
        <th><strong>Machine Type Model</strong></th>
        <th><strong>Reward Value</strong></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Power Systems S1022</td>
        <td>9105-22A</td>
        <td>$2,000</td>
      </tr>
      <tr>
        <td>Power Systems S1022s</td>
        <td>9105-22B</td>
        <td>$1,000</td>
      </tr>
      <tr>
        <td>Power Systems S1014</td>
        <td>9105-41B</td>
        <td>$1,000</td>
      </tr>
      <tr>
        <td>Power Systems S1024</td>
        <td>9105-42A</td>
        <td>$3,000</td>
      </tr>
      <tr>
        <td>Power Systems L1022</td>
        <td>9786-22H</td>
        <td>$2,000</td>
      </tr>
      <tr>
        <td>Power Systems L1024</td>
        <td>9786-42H</td>
        <td>$2,000</td>
      </tr>
      <tr>
        <td>Power Systems E1050</td>
        <td>9043-MRX</td>
        <td>$4,000</td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p>
    <span style="font-weight: 400">Details can also be found under the </span
    ><a
      href="https://www-356.ibm.com/partnerworld/wps/servlet/mem/ContentHandler/pw_ast_iitwi-eligible-product-list"
      ><span style="font-weight: 400">Eligible Product List</span></a
    ><span style="font-weight: 400">&nbsp;</span>
  </p>
  <p>
    <span style="font-weight: 400"
      >All reward funds earned will automatically be awarded to the individuals reloadable IBM
      Rewards Card.&nbsp;&nbsp;</span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >To participate in this incentive partners will need to register at </span
    ><a href="http://www.ibm.com/partnerworld/ibmpowerrewards"
      ><span style="font-weight: 400">www.ibm.com/partnerworld/ibmpowerrewards</span></a
    ><span style="font-weight: 400">&nbsp;</span>
  </p>
  <p><strong>Incentive Details&nbsp;</strong></p>
  <p><strong>Getting started&nbsp;&nbsp;</strong></p>
  <p>
    -
    <span style="font-weight: 400"
      >Ensure they have met all compliance requirements including&nbsp;&nbsp;</span
    >
  </p>
  <ul>
    <li>
      <span style="font-weight: 400">accepting the terms and conditions<br /></span>
    </li>
    <li>
      <span style="font-weight: 400">completing their profile information<br /></span>
    </li>
    <li>
      <span style="font-weight: 400">completion of the IBM Integrity Training<br /></span>
    </li>
    <li><span style="font-weight: 400">applied for the IBM rewards Card</span></li>
  </ul>
  <p><strong>Pre-Sales Incentives&nbsp;&nbsp;</strong></p>
  <ul>
    <li>
      Pre-sales activities (includes Client Meeting and Assessments) are available for individuals
      who hold a Seller or a Technical Seller role within their firm.
    </li>
    <li>
      Pre-sales activity benefits are only eligible for New Power Clients or White Space accounts.
      Individuals can send an email to power@kyirewards.com to verify if their potential client is
      considered a New Client.
    </li>
    <li>
      One benefit payment for first Client Meeting, and one benefit payment for a Client Assessment,
      for a total of $500 USD per single end user client.
    </li>
    <li>For pre-sales activity to qualify an IBM Power Seller must be present for meetings.</li>
  </ul>
  <p>
    <span style="font-weight: 400"> </span
    ><strong>How do I submit a pre-sales activity?&nbsp;</strong>
  </p>
  <p>
    <span style="font-weight: 400"
      >&nbsp;&bull;&nbsp;Click on the &lsquo;Pre-Sales Activity&rsquo; tab within the IBM Power
      Reward sales incentive. You will be&nbsp; presented with two options.&nbsp;&nbsp;</span
    >
  </p>
  <p>
    <span style="font-weight: 400">&nbsp;&bull; </span><strong>Client Meeting </strong
    ><span style="font-weight: 400"
      >- Complete the mandatory fields and upon confirmation of the meeting&nbsp; funds will be
      auto-loaded to your IBM Rewards Card.&nbsp;</span
    >
  </p>
  <p>
    <span style="font-weight: 400">&nbsp;&bull;&nbsp; </span
    ><span style="font-weight: 400"
      >Qualifying client meetings require a Deal Registration #, along with the collaboration&nbsp;
      of an IBM Power Seller. Meetings need to be substantive with the purpose of driving&nbsp;
      potential Power 10 sales&nbsp;</span
    >
  </p>
  <ul>
    <li>
      <span style="font-weight: 400">In person meeting (e.g. customer premises, event)&nbsp;</span>
    </li>
    <li>
      <span style="font-weight: 400"
        >Virtual meeting (e.g. via phone, web conference)&nbsp;<br /><br
      /></span>
    </li>
    <li>
      <span style="font-weight: 400"> </span><strong>Assessment - </strong
      ><span style="font-weight: 400"
        >Complete the mandatory fields and upon confirmation of the assessment funds will be
        auto-loaded to your IBM Rewards Card&nbsp;</span
      >
    </li>
  </ul>
  <p>
    <span style="font-weight: 400">&nbsp;&bull; </span
    ><span style="font-weight: 400"
      >The following activities are considered eligible assessments,&nbsp;</span
    >
  </p>
  <ul>
    <li><span style="font-weight: 400">Onsite PoC/Benchmark&nbsp;</span></li>
    <li><span style="font-weight: 400">IBM hosted PoC/Benchmark&nbsp;</span></li>
    <li><span style="font-weight: 400">Partner hosted PoC/Benchmark&nbsp;</span></li>
    <li><span style="font-weight: 400">Technology Zone demo&nbsp;</span></li>
    <li><span style="font-weight: 400">Try-N-Buy</span></li>
  </ul>
  <p><strong>Seller Incentives&nbsp;</strong></p>
  <ul>
    <li>
      <span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Sales incentives are available for individuals who hold a Seller and Technical Seller
            role within their firm,
          </span></span
        ></span
      >
    </li>
    <li>
      <span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Qualifying products can be found on the eligible product table found under the
            <a
              href="https://www-356.ibm.com/partnerworld/wps/servlet/mem/ContentHandler/pw_ast_iitwi-eligible-product-list"
              target="_blank"
              rel="noopener"
              >Eligible Product List
            </a></span
          ></span
        ></span
      >
    </li>
    <li>
      <span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Qualifying individuals can earn the value of the benefit by selling to a NEW Power
            Client.</span
          ></span
        ></span
      >
    </li>
  </ul>
  <p><strong>What&rsquo;s considered a New Power client?&nbsp;</strong></p>
  <ul>
    <li>
      <span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >A &ldquo;New Power Client&rdquo; is defined as an entity that has no Power8, Power9 or
            Power10 systems installed as indicated by IBM&rsquo;s install base data</span
          ></span
        ></span
      >
    </li>
  </ul>
  <p><strong>What&rsquo;s considered Competitive Displace?&nbsp;</strong></p>
  <ul>
    <li>
      <span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Competitive displace is defined as a Power10 new box sale in which the Power10 server
            is replacing a competitive hardware platform for a specific workload. There may be
            Power8, Power9 or Power10 installed elsewhere in the entity. Example: displacing Oracle
            Exadata with a Power based solution for a client where Power10 is running SAP HANA</span
          ></span
        ></span
      >
    </li>
  </ul>
  <p>
    <strong>How do I submit for a Competitive Displace?</strong
    ><span style="font-weight: 400">.&nbsp;</span>
  </p>
  <ul>
    <li>
      <span style="font-weight: 400"
        ><span class="VIiyi" lang="en"
          ><span
            class="JLqJ4b"
            data-language-for-alternatives="en"
            data-language-to-translate-into="en"
            data-phrase-index="0"
            data-number-of-phrases="1"
            ><span class="Q4iAWc"
              >Click on the &lsquo;Competitive Displace&rsquo; tab within the IBM Power UP sales
              incentive tab and complete the mandatory fields. Upon confirmation, funds will be
              auto-loaded to your IBM Rewards Card. Mandatory fields include Deal Registration
              number #, Machine Type Model of eligible Power 10 being installed, Serial number
              #.</span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        ><span class="VIiyi" lang="en"
          ><span
            class="JLqJ4b"
            data-language-for-alternatives="en"
            data-language-to-translate-into="en"
            data-phrase-index="0"
            data-number-of-phrases="1"
            ><span class="Q4iAWc">
              Opportunity Registration must indicate that this is a competitive displacement. The
              Deal Registration record must include details about the competitive displacement
              including vendor, displaced machine type models.</span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <p><strong>Eligible Business Partners&nbsp;&nbsp;</strong></p>
  <ul>
    <li>
      <span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Any PartnerWorld member who under the IBM Business Partner Agreement (HW/SW Reseller or
            SIs) who buy from IBM Distributors to fulfill End User client sales orders.
          </span></span
        ></span
      >
    </li>
    <li>
      <span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >The Business Partner firm must have an active Know Your IBM (KYI) Attachment to allow
            their individual staff to participate.</span
          ></span
        ></span
      >
    </li>
  </ul>
  <p><strong>Exclusions&nbsp;</strong></p>
  <ul>
    <li>
      <span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc"
            >Partners who fall under an Embedded Solution Agreement(&ldquo; ESA&rdquo;) contract, or
            are considered Managed Service Providers (MSP&rsquo;s)</span
          ></span
        ></span
      >
    </li>
    <li>
      <span class="VIiyi" lang="en"
        ><span
          class="JLqJ4b"
          data-language-for-alternatives="en"
          data-language-to-translate-into="en"
          data-phrase-index="0"
          data-number-of-phrases="1"
          ><span class="Q4iAWc">
            General Marketing and Services Representatives (GMSRs) are not eligible to participate
            under this incentive offering</span
          ></span
        ></span
      >
    </li>
  </ul>
  <p><strong>How do I register online?&nbsp;&nbsp;</strong></p>
  <ul>
    <li>Visit the IBM Power Rewards splash page at https://ibm.com/partnerworld/ibmpowerrewards</li>
    <li>Click on &lsquo;Explore IBM Power Rewards&rsquo;</li>
    <li>
      Set up your profile via Manage My Profile ensuring you meet all the eligibility requirements
    </li>
    <li>Select IBM Power Rewards</li>
    <li>Read and accept the IBM Power Rewards terms and conditions.</li>
    <li>
      Individuals will be notified via email when their eligible transactions are available for
      claiming.
    </li>
    <li>No cash equivalent is available under this Sales Incentive</li>
  </ul>
  <p><strong>How do I claim a sale?</strong></p>
  <ul>
    <li>
      NOTE: The ability to claim a sale is reserved for Business Partner Sellers and Technical
      Sellers<br />only.
    </li>
    <li>
      Go to &lsquo;Claim a Sale&rsquo; and identify the transaction(s) you worked on. Once the sale
      is identified click the &lsquo;Claim&rsquo; button and the benefit will flow to your IBM
      Rewards Card in time.
    </li>
    <li>
      There is a 10 day cooling period once you&rsquo;ve made your claim to allow you to correct any
      claims that were done in error. After the 10 day cooling period lapses your benefit value will
      flow to your IBM Rewards Card.
    </li>
    <li>Qualifying individuals can also split the sales benefit between one another 50/50.</li>
  </ul>
  <p><strong>Global KYI Offering Terms &amp; Conditions</strong></p>
  <ul>
    <li>
      Offering applicants agree to be bound by the KYI Supplement (including the terms contained in
      this document) and may be changed or modified at any time by notice by IBM.
    </li>
    <li>
      IBM may, based on reasonable cause, suspend or terminate the KYI Offering, in whole or in part
      at any time by providing notice, and without liability.
    </li>
    <li>
      IBM takes reasonable care to ensure that any information contained in the KYI Supplement
      (including the terms contained in this document) and in any publication in association with
      the Offering is accurate but does not make any representations or warranties or accept
      liability with respect to any errors or omissions in any information, whether written or
      verbal.
    </li>
    <li>
      IBM's decision on all matters pertaining to the KYI Offering is final and binding and no
      correspondence will be entered into.
    </li>
    <li>
      IBM reserves the right to substitute a KYI reward with a benefit of equivalent function or
      value.
    </li>
    <li>This KYI Offering is void where prohibited or restricted by applicable law.</li>
    <li>
      IBM will report to local tax authorities on behalf of the participating individual, all
      redeemed<br />earnings within the calendar year (where required).
    </li>
    <li>IBM retains the right to audit any or all submissions under the KYI Offering.</li>
    <li>
      IBM may request copies of invoices, delivery orders or other documentation as evidence that
      the<br />participant has complied with the KYI Supplement (including the terms contained in
      this<br />document). IBM may retain a copy of this documentation.
    </li>
    <li>
      Each participant must comply with the highest ethical principles in performing under the
      KYI<br />Offering. A participant must not offer or make payments or gifts (monetary or
      otherwise) to anyone for the purpose of wrongfully influencing decisions in favour of IBM,
      directly or indirectly. IBM may terminate a participant&rsquo;s participation under the KYI
      Offering immediately in case of 1) a breach of this clause or 2) when IBM reasonably believes
      such a breach has occurred or is likely to occur.
    </li>
    <li>
      Each participant commits and is obliged to assure that KYI points and redeemed rewards
      (reloadable reward cards or merchandise when eligible) are used only for appropriate purposes
      and in full compliance with (a) all applicable laws and regulations, including those that
      prohibit corruption and bribery, (b) the IBM Code of Conduct (available at the following IBM
      Internal<br />website:
      <a
        href="https://www.ibm.com/partnerworld/program/code-of-conduct"
        target="_blank"
        rel="noopener"
        >https://www.ibm.com/partnerworld/program/code-of-conduct</a
      >
      and (c) Section 4.2, Prohibition on Gifts, of the General Terms of the IBM Business Partner
      Agreement. In no circumstances may the reward card be given, transferred or sold to third
      parties, including in particular, customers or prospective customers of the IBM Business
      Partner. IBM may terminate a participant&rsquo;s participation under this Offering immediately
      in case of 1) a breach of this clause or 2) when IBM reasonably believes such a breach has
      occurred or is likely to occur.
    </li>
    <li>
      If a KYI participant is found at any time not to be in full compliance with KYI Terms and
      Conditions, IBM has the right to recover any issued rewards and unspent funds from the
      participating Business Partner representative(s). Additionally, IBM reserves the right to
      terminate the Business Partner representative from the KYI offering immediately upon notice
      and may deny participation in the KYI offering in the future. Participants must be registered
      in KYI having accepted the terms and conditions prior to receiving a KYI reward.
    </li>
    <li>
      IBM Channel Ecosystem operates on a Preferred Distributor model. To qualify for IBM Hardware
      or Software incentives, each Business Partner is required to select their Preferred
      Distributor. Only purchases made from your Preferred Distributor will be counted towards
      incentives described in this offering.
    </li>
    <li>This offer can be combined with other offers and incentives</li>
  </ul>
  <p><strong>Participant Eligibility</strong></p>
  <ul>
    <li>
      Your company must accept the KYI Attachment which grants IBM permission to reward you directly
    </li>
    <li>
      You must belong to a firm that holds an active PartnerWorld Agreement and IBM Business
      Partner<br />Agreement
    </li>
    <li>
      Only full-time regular Business Partner employees who qualify as eligible Seller or Technical
      Seller personnel may participate in IBM Power Rewards. No other Business Partner employees may
      participate, including contractors, administrative, management, and part-time sales or
      technical personnel
    </li>
    <li>All firms participating must be certified as completing the IBM Code of Conduct</li>
    <li>You must have an IBM ID associated with your company</li>
    <li>Participants must use their company email address for their id&rsquo;s</li>
    <li>
      It is the participant&rsquo;s responsibility to ensure they secure approval from the principal
      officer, senior manager, or executive of your Business Partner Company (Business) with the
      authority to make personnel decisions on behalf of your company
    </li>
    <li>
      KYI Attachment contract documents may be electronically accepted, or for certain countries
      designated by IBM, a hard copy signature may be required by the APA (Authorised Profile
      Administrator) or PRC (Primary Relationship Contact) within the PartnerWorld program, to grant
      permission for the qualifying employees of your company to earn and redeem points through the
      KYI offering
    </li>
  </ul>
  <p><strong>IBM Rewards Card</strong></p>
  <p>
    All benefits earned under this sales incentive will be applied to an individual&rsquo;s IBM
    Rewards Card. Qualifying participants must submit a full Know Your Customer (KYC) pack via the
    IBM Rewards Card application form, as well as provide a copy of their identification and
    documentation that offers proof of residency. IBM Rewards Card will be delivered to the
    applicant within 28 days from receipt of complete KYC documentation and will be personalized
    with the individuals name on it. Only the individual named on the IBM Rewards Card will be able
    to use the IBM Rewards Card. The IBM Rewards Card does not allow for cash withdrawal from an ATM
    machine. All IBM Reward Cards expire after a two (2) year period from the date of issuance after
    which a new card will be issued to the eligible recipient in line with the KYC requirements
    herein.
  </p>
  <p><strong>Government Entities</strong></p>
  <p>
    <strong
      >Government Entities and Entities governed by Anti-Bribery and Graft Act (Kim Young-ran
      Act)</strong
    >
  </p>
  <ul>
    <li>
      All Business Partners who are Government Entities (GE) or Government Owned Entities (GOE), a
      part of a GE, or controlled by a GE, as defined in the KYI Attachment, or entities governed by
      Kim Young-ran Act* are prohibited from participating in the KYI Offering. IBM reserves the
      right to reverse KYI points awarded to Business Partners who are Government Entities, and IBM
      will make such determination at its sole discretion. For a definition of what IBM includes as
      a GE please click here.
    </li>
  </ul>
  <p>
    <strong
      >Sales to Government Entities and Entities governed by Anti-Bribery and Graft Act (Kim
      Young-ran Act):</strong
    >
  </p>
  <ul>
    <li>
      Sales to Government Entities (GE) or Government Owned Entities (GOE), or to a business that is
      a part of a GE, or controlled by a GE, as defined in the KYI Attachment, or entities governed
      by Kim Young-ran Act* do not accrue KYI points. IBM will not import these invoices onto the
      KYI website. It is the responsibility of the participant to not claim any such invoices that
      were imported to the KYI website in error. IBM reserves the right to reverse KYI points
      awarded for any such invoice. For a definition of what IBM includes as a GE please click here
    </li>
  </ul>
  <p>
    *Entities governed by the Anti-Bribery and Graft Act (Kim Young-ran Act) are not qualifying
    customers. Consequently, sales to entities governed by Anti-Bribery and Graft Act (Kim Young-ran
    Act) do not qualify for KYI points.
  </p>
  <p><strong>Integrity Training</strong></p>
  <ul>
    <li>
      To earn a benefit through this offering, participants must have completed IBM Integrity
      Training within the last two years
    </li>
    <li>Integrity Training is free of charge and approximately 30 minutes in length</li>
    <li>
      A participant cannot redeem any earned KYI points or participate in any Sales Incentive until
      this<br />training is fulfilled/validated
    </li>
    <li>
      To request access to the IBM Integrity Training participants can email power@kyirewards.com
    </li>
  </ul>
  <p><strong>NOTE: Integrity training is mandatory for ALL participating users.&nbsp;</strong></p>
  <p><strong>Participant limit per Business Partner</strong></p>
  <p>
    Each Business Partner&rsquo;s Country Enterprise ID (CEID) has a limit of 100 sales and
    technical employees that may earn a benefit through the KYI Offering. Participation is at the
    discretion of the Authorized Profile Administrator (APA) or Primary Relationship Contact (PRC)
    within the Business Partner company, subject to these terms and conditions.
  </p>
  <p><strong>Eligible Sales</strong></p>
  <p>
    Only sales of new products are eligible to receive a KYI reward. IBM defines eligible new
    products as those resulting in the placement of a new machine or licensed program serial number.
    Conversions,<br />upgrades, and MES's (Miscellaneous Equipment Specification) to existing serial
    numbers do not qualify as new products. Products acquired for Business Partner internal use or
    for demonstration or development use do not qualify for points under the terms of this KYI
    Offering.
  </p>
  <p><strong>Termination</strong></p>
  <p>
    IBM may terminate the involvement on notice of any registered participant in the IBM KYI Reward
    incentive immediately in case of 1) a breach of the KYI Attachment and these Terms or 2) when
    IBM reasonably believes such a breach has occurred or is likely to occur
  </p>
  <p>
    Failure of a registered participant(s) to comply with these KYI incentive terms and conditions
    may result in:&nbsp;
  </p>
  <p>
    The termination of a registered participant&rsquo;s involvement in the IBM Power UP Reward
    incentive and/or&nbsp;
  </p>
  <p>
    The cancellation of all or part of any accrued reward funds earned by the registered
    participant&nbsp;
  </p>
  <p>
    Participation in the offering is automatically terminated upon:<br />&bull; The death of the
    registered participant<br />&bull; Termination of the registered participant&rsquo;s employment
    with an IBM Business Partner&nbsp;<br />&bull; Termination of the Business Partner Agreement or
    PartnerWorld Agreement with IBM
  </p>
  <p>
    Each participant must comply with the highest ethical principles in performing and activities
    related to the IBM Power Rewards incentive. A participant must not offer or make payments or
    gifts (monetary or otherwise) to anyone for the purpose of wrongfully influencing decisions in
    favour of IBM, directly or indirectly. IBM may terminate a participant&rsquo;s participation
    under the offering immediately in case of 1) a breach of this clause or 2) when IBM reasonably
    believes such a breach has occurred or is likely to occur
  </p>
  <p>
    &bull; A registered participant may terminate their participation in the IBM Power Rewards
    incentive by providing notice in writing to power@kyirewards.com. Upon providing notice of
    withdrawal Business Partners will have a two (2) week period to redeem any benefit remaining in
    their account before forfeiting their earnings.
  </p>
  <p><strong>Privacy</strong></p>
  <p>
    &bull; IBM will provide to its contractors and agents for the purpose of administering the
    offering, certain personal information about participants and their entries appropriate for the
    calculation of awards and prizes. Any information relating to the KYI Rewards incentive
    subsequently disclosed by a participant to IBM or its contractors and agents may be sought,
    collected, used or disclosed in accordance with IBM&rsquo;s Privacy Policy available at
    https://ibm.com/privacy.
  </p>
  <p>&bull; , for one or more of the following purposes:</p>
  <ul>
    <li>
      Administering the KYI Rewards incentive and the provision or performance of services relating
      to the offering; and Planning, research and the promotion of goods or services of IBM
      associated with the offering.
    </li>
    <li>
      The agency managing the offering and handling personal information on behalf of IBM is
      MotivForce Marketing &amp; Incentives (MMI)
    </li>
  </ul>
  <p>
    &bull; All data is retained for a period of three years in accordance with IBM&rsquo;s privacy
    statement relating to this announcement.
  </p>
  <p>
    &bull; Each participant authorizes IBM and any of its contractors or agents to seek access to,
    collect and use that information or disclose that information between themselves for the above
    purposes<br />&nbsp;<br /><strong>Tax Liability</strong>
  </p>
  <p>
    Participants are solely responsible for their own social security contributions and taxes. IBM
    accepts no liability for any consequential tax or social security resulting from the receipt of
    compensation under this program
  </p>
  <p>For individual country tax requirements see <strong>below</strong>&nbsp;</p>
  <p><strong>Germany, Italy:</strong></p>
  <p>
    IBM will pay the Income tax resulting from rewards issued to participants. Reward amounts at the
    KYI programme website for these countries will show the amount payable to the individual after
    tax has been deducted. All tax payments will be held on IBM&rsquo;s behalf by the agency
    managing the KYI Offering.
  </p>
  <p>
    <strong>For Korea:<br /></strong><br />IBM will withhold the income tax resulting from rewards
    issued to participants and pay it to local tax authorities; Reward amounts at the KYI program
    website will show the amount payable to the individual after withholding tax has been deducted.
    Participants/ KYI coordinators will be required to share their Resident Registration Number
    (RRN) and home address in Korea to Korea Payroll for withholding tax filing purposes.
  </p>
  <p><strong>Canada</strong></p>
  <p>
    The KYI Coordinator will provide each sales rep who has redeemed more than CDN $500 in KYI
    points within the 2022 calendar year with a T4A form for tax purposes. You will be required to
    share your Social Insurance Number for filing purposes on the KYI website.
  </p>
  <p><strong>USA</strong></p>
  <p>
    The KYI Coordinator will provide each sales rep who has redeemed more than USD $600 in KYI
    points within the 2022 calendar year with a 1099-Misc form for tax purposes. You will be
    required to share your Social Security Number for filing purposes on the KYI website.
  </p>
  <p><strong>AP (Malaysia, Indonesia, Thailand, Singapore, India, Sri Lanka)</strong></p>
  <p>
    The KYI coordinator will collect participants' tax file number and report earnings through this
    KYI Offering to the local tax authorities annually. You will be required to share your personal
    tax information for filing purposes on the KYI website.
  </p>
  <p>
    <strong
      >EP (Belgium, Finland, France, Luxembourg, Netherlands, Poland, Portugal, Spain, Sweden,
      Switzerland</strong
    >
  </p>
  <p>
    The KYI coordinator will collect participants' tax file number and report earnings through this
    KYI Offering to the local tax authorities annually. You will be required to share your personal
    tax information for filing purposes on the KYI website.
  </p>
  <p><strong>GCG (Taiwan)</strong></p>
  <p>
    The KYI coordinator will collect participants' tax file number and report earnings through this
    KYI Offering to the local tax authorities annually. You will be required to share your personal
    tax information for filing purposes on the KYI website.
  </p>
</div>
<div class="modal-footer d-flex justify-content-end">
  <div class="d-flex justify-content-end">
    <button class="btn" type="button" (click)="activeModal.close()">
      {{ 'Close' | translate }}
    </button>
  </div>
</div>
